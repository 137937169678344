@import "../abstracts/variables";
@import "../abstracts/functions";
@import "../abstracts/keyframes";

:root {
  --height: 100vh;
}

.animate {
  &__float1 {
    animation: floatingY 4s ease-in-out infinite;
  }

  &__float2 {
    animation: floatingY 6s ease-in-out infinite;
  }

  &__rotate1 {
    animation: orbit-rotate 6s linear infinite;
    transform-origin: center center;
  }

  &__rotate-reverse-1 {
    animation: orbit-reverse 10s linear infinite;
  }

  &__upDown {
    animation: upDown2 1s linear infinite;
  }

  &__jump {
    animation: bounceDown-on 1s linear infinite;
  }

  &__jump-1 {
    animation: bounceDown 1.5s linear infinite;
  }

  &__jump-2 {
    animation: bounceDown 1.8s linear infinite 1s;
  }

  &__jump-3 {
    animation: bounceDown 1.5s linear infinite 1.5s;
  }

  &__jump-4 {
    animation: bounceDown 1.8s linear infinite 2s;
  }

  &__scale1 {
    animation: scale1 1s ease infinite;
    transform-origin: center;
    transform-box: fill-box;
  }

  &__scale2 {
    animation: scale1 2s ease infinite;
    transform-origin: center;
    transform-box: fill-box;
  }

  &__scale3 {
    animation: scale1 3s ease infinite;
    transform-origin: center;
    transform-box: fill-box;
  }
  &__scale4 {
    animation: scale1 4s ease infinite;
    transform-origin: center;
    transform-box: fill-box;
  }

  &__pulse {
    border-radius: 50%;
    animation: pulse 2s infinite;
  }
}

.section {
}

input[type="number"] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.wrapper {
  &__chat-wrap-list {
    height: calc(100% - (125px + 1.5rem));
    overflow: auto;
  }
  &__category-slide {
    display: flex;
    align-items: center;
    overflow: auto;
    margin: 0 -5px;
    &::-webkit-scrollbar {
      display: none;
    }

    .items {
      margin: 0 5px;
      flex-shrink: 0;
      padding: 0.5rem 1rem;
      background: #082a3a;
      border: 1px solid #bcc6c8;
      border-radius: 16px;
      width: max-content;
      text-align: center;

      &.active {
        background: rgba(0, 230, 246, 0.12);
        border: 1px solid rgba(0, 230, 246, 0.24);
        filter: drop-shadow(0px 8px 24px #04131b);
      }
    }
  }
  &__box-chat {
    height: calc(100% - (253px + 1.5rem));
    overflow: auto;
  }
  &__type-chat {
    padding: 1rem 0;
    padding-bottom: 2rem;
    background: #002429;
    border-radius: 20px 20px 0px 0px;

    .field {
      .send {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(8, 42, 58, 0.48);
        border: 1px solid rgba(0, 230, 246, 0.16);
        backdrop-filter: blur(10px);
      }
      input {
        height: 48px;
        width: 100%;
        box-shadow: none;
        outline-style: none;
        padding: 0 1rem;
        background: rgba(8, 42, 58, 0.48);
        border: 1px solid rgba(0, 230, 246, 0.16);
        border-radius: 24px;

        &:hover {
          box-shadow: none;
          outline-style: none;
          padding: 0 1rem;
          background: rgba(8, 42, 58, 0.48);
          border: 1px solid rgba(0, 230, 246, 0.16);
          border-radius: 24px;
        }
        &:focus {
          box-shadow: none;
          outline-style: none;
          padding: 0 1rem;
          background: rgba(8, 42, 58, 0.48);
          border: 1px solid rgba(0, 230, 246, 0.16);
          border-radius: 24px;
        }
        &:active {
          box-shadow: none;
          outline-style: none;
          padding: 0 1rem;
          background: rgba(8, 42, 58, 0.48);
          border: 1px solid rgba(0, 230, 246, 0.16);
          border-radius: 24px;
        }
      }
    }
  }
  &__admin-text-chat {
    max-width: 75vw;
    margin-right: auto;

    .wrap {
      .user {
        display: flex;
        align-items: flex-end;
        > img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          object-fit: cover;
        }

        .text__desc {
          padding: 1rem;
          background: color("black");
          border-radius: 16px 16px 16px 0px;
        }
      }
    }
  }
  &__user-text-chat {
    max-width: 90vw;
    margin-left: auto;

    .text__desc {
      padding: 1rem;
      background-color: white;
      border-radius: 16px 16px 0px 16px;
    }
  }
  &__chatlist-detail {
    position: fixed;
    width: 100%;
    height: 100vh;
    overflow: auto;
    left: 0;
    top: 150%;
    z-index: 14;
    transition: 0.5s;

    @media screen and (max-width: $br-sm) {
      height: var(--height);
    }

    &.active {
      top: 0;
    }
    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1rem 0;
    }

    .title__chat {
      border: none;
      border-top: 1px solid #ffffff1f;
      border-bottom: 1px solid #ffffff1f;
      padding: 1rem 0;

      .user {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .profile {
          display: flex;
          align-items: center;
          > img {
            width: 44px;
            height: 44px;
            border-radius: 50%;
            object-fit: cover;
          }
        }
      }
    }
  }
  &__chatList {
    &.active {
      background: #00e6f614;
    }
    .container {
      .detail {
        padding: 1rem 0;
        display: flex;
        align-items: flex-start;

        .img__user {
          position: relative;
          img {
            width: 48px;
            height: 48px;
            object-fit: cover;
            border-radius: 50%;
          }
          .count {
            width: 16px;
            height: 16px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: color("red");
            color: white;
            position: absolute;
            right: -1px;
            bottom: -1px;
          }
        }
      }
    }
  }
  &__modal-chat-request {
    position: fixed;
    width: 100%;
    height: 100vh;
    overflow: auto;
    left: 0;
    top: 150%;
    z-index: 13;
    transition: 0.5s;

    @media screen and (max-width: $br-sm) {
      height: var(--height);
    }

    &.active {
      top: 0;
    }

    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1rem 0;
    }
  }
  &__card-review {
    margin-bottom: 1rem;
    .head {
      border-radius: 20px;
      border: 1px solid #204650;
      padding: 0.5rem !important;

      .top {
        display: flex;
        align-items: center;
      }

      hr {
        border-top: 1px solid #ffffff14;
      }

      .content {
        display: flex;
        align-items: flex-center;
        justify-content: flex-end;

        .star {
          display: flex;
          align-items: center;
          margin: 0 -2px;
          svg {
            margin: 0 2px;
          }
        }

        .desc {
          display: flex;
          align-items: flex-start;
          justify-content: flex-end;
          div {
            .tag {
              padding: 6px 13px;
              border-radius: 30px;
              line-height: initial;
              background: #00e6f61f;
              display: inline-block;
            }
          }
          .cover {
            width: 70px;
            height: 70px;
            border-radius: 8px;
            overflow: hidden;
            position: relative;

            &::after {
              content: "";
              position: absolute;
              width: 100%;
              height: 100%;
              background: linear-gradient(
                  180deg,
                  rgba(12, 12, 12, 0) 0%,
                  rgba(12, 12, 12, 0.16) 100%
                ),
                radial-gradient(
                  50% 50% at 50% 50%,
                  rgba(12, 12, 12, 0.32) 0%,
                  rgba(12, 12, 12, 0.32) 100%
                );
              left: 0;
              top: 0;
            }
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
      }
    }
  }

  //   .wrapper__filed-general > div {
  //   width: 100%;
  // }

  // .wrapper__filed-general > div > div {
  //   background: transparent;
  //   border: none;
  //   text-align: left;
  // }

  // #react-select-2-listbox {
  //   background-color: white !important;
  //   color: #04131b;
  // }

  &__filed-general {
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    background: rgba(8, 42, 58, 0.48);
    border: 1px solid rgba(0, 230, 246, 0.16);
    border-radius: 50px;

    > div {
      width: 100%;

      div [class*="singleValue"] {
        color: white;
      }

      > div,
      > div:focus {
        background: transparent;
        border: none;
        text-align: left;
        box-shadow: none !important;
        color: white;
        padding: 0px !important;
        cursor: text;

        input {
          color: white !important;
        }
      }

      div[class*="IndicatorsContainer"] {
        display: none;
      }

      #react-select-2-listbox {
        background-color: white !important;
        color: #04131b;
      }
    }
    input {
      box-shadow: none;
      border-radius: 0;
      outline-style: none;
      background-color: transparent;
      border: none;
      padding: 0;
      color: #fff;

      &:hover {
        box-shadow: none;
        border-radius: 0;
        outline-style: none;
        background-color: transparent;
        border: none;
        padding: 0;
      }
      &:focus {
        box-shadow: none;
        border-radius: 0;
        outline-style: none;
        background-color: transparent;
        border: none;
        padding: 0;
      }
      &:active {
        box-shadow: none;
        border-radius: 0;
        outline-style: none;
        background-color: transparent;
        border: none;
        padding: 0;
      }
    }
  }
  &__wrap-up {
    position: fixed;
    z-index: 11;
    width: 100%;
    left: 0;
    top: 100%;
    height: 100%;
    background: #1a1a1a;
    opacity: 0.8;
    transition: 0.5s;

    &.updated {
      z-index: 15;
    }

    &.active {
      top: 0;
    }
  }
  &__up-request {
    position: fixed;
    z-index: 12;
    width: 100%;
    left: 0;
    bottom: -150%;
    background-color: color("black");
    border-radius: 20px 20px 0 0;
    transition: 0.5s;

    &.updated {
      z-index: 16;
    }

    &.active {
      bottom: 0;
    }
    .head {
      padding: 1.5rem 0;
      padding-bottom: 2rem;
      .ornamen {
        width: 34px;
        height: 4px;
        border-radius: 50px;
        background-color: #464f51;
        margin: 0 auto;
      }
    }

    .body {
      padding: 1.5rem 0;
      padding-top: 0;

      .general {
        .arrow {
          width: 57px;
          height: 57px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .review {
        textarea {
          padding: 0.5rem;
          border: 1px solid #204650;
          border-radius: 16px;
          background-color: transparent;
          outline-style: none;
          box-shadow: none;
          width: 100%;
        }
        .reveiw__list {
          > div {
            margin: 0 -0.5rem;
            .items {
              margin: 0 0.5rem;
              margin-bottom: 1rem;
            }
            .tag {
              padding: 8px 15px;
              border-radius: 6px;
              border: 1px solid #204650;
            }
          }
        }
      }

      .size {
        .list__size {
          display: flex;
          align-items: center;
          overflow: auto;
          margin: 0 -5px;
          &::-webkit-scrollbar {
            display: none;
          }

          .items {
            margin: 0 5px;
            flex-shrink: 0;
            background: #082a3a;
            border: 1px solid #bcc6c8;
            border-radius: 50%;
            width: max-content;
            text-align: center;
            width: 48px;
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: center;

            &.active {
              background: rgba(0, 230, 246, 0.12);
              border: 1px solid rgba(0, 230, 246, 0.24);
              filter: drop-shadow(0px 8px 24px #04131b);
            }
          }
        }
      }
      .time {
        .list__time {
          display: flex;
          align-items: center;
          overflow: auto;
          margin: 0 -5px;
          &::-webkit-scrollbar {
            display: none;
          }

          .items {
            margin: 0 5px;
            flex-shrink: 0;
            padding: 1rem;
            background: #082a3a;
            border: 1px solid #bcc6c8;
            border-radius: 16px;
            width: max-content;
            text-align: center;

            &.active {
              background: rgba(0, 230, 246, 0.12);
              border: 1px solid rgba(0, 230, 246, 0.24);
              filter: drop-shadow(0px 8px 24px #04131b);
            }
          }
        }
      }

      .date {
        .dateFormat {
          .react-datepicker__header {
            background-color: transparent;
            border: none;

            .react-datepicker__day-names {
              .react-datepicker__day-name {
                color: color("gray-2");
              }
            }
          }

          .react-datepicker__navigation {
            top: 10px;
          }

          .react-datepicker {
            width: 100%;
            font-size: 14px;
            background-color: transparent;
            border: none;
          }
          .react-datepicker__month-container {
            width: 100%;
          }

          .react-datepicker__current-month {
            color: color("gray-2");
            padding-bottom: 1rem;
          }

          .react-datepicker__day-name,
          .react-datepicker__day,
          .react-datepicker__time-name {
            width: 2.5rem;
            line-height: 2.5rem;
          }

          .react-datepicker__day {
            color: white;
          }

          .react-datepicker__day--selected {
            background: rgba(0, 230, 246, 0.12);
            border: 1px solid rgba(0, 230, 246, 0.24);
            box-shadow: 0px 8px 24px #04131b;
            border-radius: 12px;
          }

          .react-datepicker__day--keyboard-selected {
            background-color: transparent;
          }

          .react-datepicker__week {
            .react-datepicker__day--disabled {
              color: white;
              opacity: 0.5;
            }
          }
        }
      }

      .cuisine {
        .list__wrap {
          max-height: 60vh;
          overflow: auto;
          &.updated {
            max-height: 48vh;
          }
          .items {
            width: 100%;
            padding: 1rem;
            background: #082a3a;
            border: 1px solid #204650;
            border-radius: 16px;
            display: flex;
            align-items: center;
            margin-bottom: 1rem;
            transition: 0.5s;

            &:last-child {
              margin-bottom: 0;
            }

            .line {
              width: 1px;
              height: 30px;
              background-color: #204650;
              margin: 0 10px;
              display: inline-block;
            }

            &.active {
              border: 1px solid color("tosca");
              box-shadow: 0px 8px 24px #04131b;
              .line {
                background-color: color("tosca");
              }
            }
          }
        }
      }

      .occasion {
        .list__wrap {
          .items {
            width: 100%;
            padding: 1rem;
            background: #082a3a;
            border: 1px solid #204650;
            border-radius: 16px;
            display: flex;
            align-items: center;
            margin-bottom: 1rem;
            transition: 0.5s;

            &:last-child {
              margin-bottom: 0;
            }

            .line {
              width: 1px;
              height: 30px;
              background-color: #204650;
              margin: 0 10px;
              display: inline-block;
            }

            &.active {
              border: 1px solid color("tosca");
              box-shadow: 0px 8px 24px #04131b;
              .line {
                background-color: color("tosca");
              }
            }
          }
        }
      }

      .upcoming {
        .select {
          .items {
            width: 100%;
            border: 1px solid #1d4049;
            border-radius: 16px;
            padding: 0.7rem 1rem;

            &.active {
              border: 1px solid #00e6f6;
              filter: drop-shadow(0px 8px 24px #04131b);
              color: white !important;
            }
          }
        }
      }
    }
  }
  &__logo-modal {
    width: 100px;
    height: 100px;
    border-radius: 50%;

    &::before {
      position: absolute;
      content: "";
      width: 140px;
      height: 140px;
      border-radius: 50%;
      background: rgba(0, 230, 246, 0.2);
      filter: blur(28px);
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  &__field-profile {
    box-shadow: none;
    border-radius: 0;
    outline-style: none;
    background-color: transparent;
    border: none;
    padding: 0;

    &::-webkit-input-placeholder {
      /* Edge */
      color: white;
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: white;
    }

    &::placeholder {
      color: white;
    }

    &:hover {
      box-shadow: none;
      border-radius: 0;
      outline-style: none;
      background-color: transparent;
      border: none;
      padding: 0;
    }
    &:focus {
      box-shadow: none;
      border-radius: 0;
      outline-style: none;
      background-color: transparent;
      border: none;
      padding: 0;
    }
    &:active {
      box-shadow: none;
      border-radius: 0;
      outline-style: none;
      background-color: transparent;
      border: none;
      padding: 0;
    }
  }
  &__photo-profile {
    width: 100px;
    height: 100px;
    border-radius: 50%;

    input {
      position: absolute;
      z-index: -1;
      visibility: hidden;
      opacity: 0;
    }

    &::before {
      position: absolute;
      content: "";
      width: 140px;
      height: 140px;
      border-radius: 50%;
      background: rgba(0, 230, 246, 0.2);
      filter: blur(28px);
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    > img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }

    .edit {
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: color("tosca");
      border-radius: 50%;
      position: absolute;
      right: 5px;
      bottom: -1px;
    }
  }
  &__wrap-opening {
    background: rgba(8, 42, 58, 0.48);
    border: 1px solid rgba(0, 230, 246, 0.16);
    backdrop-filter: blur(10px);
    border-radius: 24px;
    padding: 1rem;
  }
  &__wrap-all-screan-home {
    height: var(--height);

    .scroller {
      height: 100%;
      overflow: auto;
    }
  }
  &__top-nav-profile {
    padding: 1.5rem 0;
  }
  &__alertMemberDetail {
    position: absolute;
    z-index: 5;
    background-color: color("black");
    width: 100%;
    height: 100%;
    top: 100%;
    left: 0;
    padding: 2rem 0;
    transition: 0.5s;

    &.active {
      top: 0;
    }

    div {
      div {
        .annual__wrap {
          border-radius: 20px;
          border: 1px solid #ffffff14;
          padding: 1.5rem 1rem;

          .limit {
            display: inline-block;
            padding: 4px 16px;
            border-radius: 40px;
            background: #e9352752;
          }
        }
      }
    }
  }
  &__modal-wrap {
    display: block;
    // padding-right: 17px;
    visibility: hidden;
    transition: 0.5s;
    background-color: transparent !important;

    .bg__wrap {
      background: #1a1a1a;
      opacity: 0.8;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 999;
      width: 100%;
      height: 100%;
    }

    .modal-dialog {
      z-index: 1059;

      .modal-content {
        padding: 40px 30px;
        background: #082a3a;
        box-shadow: 0px 24px 44px rgba(0, 0, 0, 0.15);
        border-radius: 24px;

        &.logout {
          div {
            .round {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 141px;
              height: 141px;
              border-radius: 50%;
              background: rgba(255, 255, 255, 0.08);
              margin: 0 auto;
            }
          }
        }
      }
    }
    &.show {
      visibility: visible;
    }
  }
  &__alert {
    position: absolute;
    max-width: 1300px;
    @media screen and (max-width: $br-xxl) {
      max-width: 85%;
    }

    &.membership {
      left: 50%;
      width: 100%;
      transform: translateX(-50%);
      bottom: -20rem;
      padding: 1rem;
      background: linear-gradient(
        88.17deg,
        rgba(255, 255, 255, 0.06) 0%,
        rgba(255, 255, 255, 0.08) 101.93%
      );
      box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.25);
      backdrop-filter: blur(10px);
      border-radius: 16px;
      z-index: 3;
      transition: 0.5s;

      &.active {
        bottom: 6rem;
      }
    }
  }
  &__card-reservation {
    border-radius: 14px;
    background: rgba(8, 42, 58, 0.48);
    box-shadow: 0px 24px 27px rgba(0, 0, 0, 0.08);
    overflow: hidden;
    .bottom {
      padding: 1rem;
      width: 100%;
      background: rgba(255, 255, 255, 0.02);

      .items {
        margin: 0 4px;
        padding: 6px 13px;
        border-radius: 30px;
        line-height: initial;
        display: flex;
        align-items: center;
        background: #ffffff0d;
      }
    }
    .head {
      padding: 1rem;

      .top {
        display: flex;
        align-items: center;
      }

      hr {
        border-top: 1px solid #ffffff14;
      }

      .content {
        display: flex;
        align-items: flex-center;
        justify-content: flex-end;

        .star {
          display: flex;
          align-items: center;
          margin: 0 -2px;
          svg {
            margin: 0 2px;
          }
        }

        .desc {
          display: flex;
          align-items: flex-start;
          justify-content: flex-end;
          div {
            .tag {
              padding: 6px 13px;
              border-radius: 30px;
              line-height: initial;
              background: #00e6f61f;
              display: inline-block;
            }
          }
          .cover {
            width: 70px;
            height: 70px;
            border-radius: 8px;
            overflow: hidden;
            position: relative;

            &::after {
              content: "";
              position: absolute;
              width: 100%;
              height: 100%;
              background: linear-gradient(
                  180deg,
                  rgba(12, 12, 12, 0) 0%,
                  rgba(12, 12, 12, 0.16) 100%
                ),
                radial-gradient(
                  50% 50% at 50% 50%,
                  rgba(12, 12, 12, 0.32) 0%,
                  rgba(12, 12, 12, 0.32) 100%
                );
              left: 0;
              top: 0;
            }
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
      }
    }
  }
  &__search {
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    background: rgba(8, 42, 58, 0.48);
    border: 1px solid rgba(0, 230, 246, 0.16);
    border-radius: 50px;
    input {
      box-shadow: none;
      border-radius: 0;
      outline-style: none;
      background-color: transparent;
      border: none;
      padding: 0;

      &::-webkit-input-placeholder {
        /* Edge */
        color: white;
      }

      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: white;
      }

      &::placeholder {
        color: white;
      }

      &:hover {
        box-shadow: none;
        border-radius: 0;
        outline-style: none;
        background-color: transparent;
        border: none;
        padding: 0;
      }
      &:focus {
        box-shadow: none;
        border-radius: 0;
        outline-style: none;
        background-color: transparent;
        border: none;
        padding: 0;
      }
      &:active {
        box-shadow: none;
        border-radius: 0;
        outline-style: none;
        background-color: transparent;
        border: none;
        padding: 0;
      }
    }
  }
  &__make-request {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 1rem 0;
    padding-top: 2rem;
    z-index: 10;
    background: linear-gradient(
      180deg,
      rgba(12, 12, 12, 0) 4.2%,
      #0c0c0c 56.47%
    );

    .message {
      width: 48px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #1a1a1a;
      border-radius: 50%;

      .count {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: color("tosca");
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 8px;
        top: 9px;
      }
    }
  }
  &__field-input {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 1rem;
    background: rgba(8, 42, 58, 0.48);
    border: 1px solid rgba(0, 230, 246, 0.16);
    border-radius: 16px;
    input {
      width: 100%;
      border: none;
      outline-style: none;
      box-shadow: none;
      background-color: transparent;

      &:hover {
        border: none;
        outline-style: none;
        box-shadow: none;
        background-color: transparent;
      }
      &:focus {
        border: none;
        outline-style: none;
        box-shadow: none;
        background-color: transparent;
      }
      &:active {
        border: none;
        outline-style: none;
        box-shadow: none;
        background-color: transparent;
      }
    }
  }
  &__blur-wrap {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 2;
    background: radial-gradient(
      59.2% 59.2% at 50% 50%,
      #00e6f6 0%,
      rgba(0, 230, 246, 0) 100%
    );
    mix-blend-mode: color;
  }
  &__bg-first {
    background: linear-gradient(180deg, rgba(8, 42, 58, 0) 0%, #082a3a 100%);
  }
  &__loading-video {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 9999999997;
    background-color: color("white");
    transition: 0.5s;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;

    &.visibelity__hidden {
      opacity: 0;
      // display: none;
      visibility: hidden;
    }
  }
  &__wrap-all-screan {
    height: 100vh;
    @media screen and (max-width: $br-sm) {
      height: var(--height);
    }
  }
  &__verification {
    .vi__wrapper {
      width: 100%;

      .vi__container {
        max-width: 303px;
        height: 56px;
        gap: 16px;

        .character {
          background: rgba(8, 42, 58, 0.48);
          border: 1px solid rgba(0, 230, 246, 0.16);
          border-radius: 20px;
          color: white;
          line-height: 56px;
          font-size: 24px;
        }

        .character--selected {
          border: 1px solid white;
        }
      }
    }
  }
  &__form-input {
    .number {
      display: flex;
      align-items: center;
      background: rgba(8, 42, 58, 0.48);
      border: 1px solid rgba(0, 230, 246, 0.16);
      border-radius: 16px;
      padding: 9px 12px;
      input {
        width: 100%;
        background-color: transparent;
        border: none;
        outline-style: none;
        box-shadow: none;

        &:hover {
          background-color: transparent;
          border: none;
          outline-style: none;
          box-shadow: none;
        }
        &:active {
          background-color: transparent;
          border: none;
          outline-style: none;
          box-shadow: none;
        }
        &:focus {
          background-color: transparent;
          border: none;
          outline-style: none;
          box-shadow: none;
        }
      }
      .wrap {
        display: flex;
        align-items: center;
        background-color: #082a3a;
        padding: 7px 7px;
        border-radius: 36px;
        .react-tel-input {
          display: inline-block;
          width: 29px;
          input,
          .search-emoji {
            display: none;
          }

          .search-box {
            display: block;
            width: calc(100% - 10px);
            margin: 0;
            padding: 15px 12px;

            &:hover,
            &:focus {
              border: 1px solid #cacaca;
            }
          }

          .flag-dropdown {
            border: none;

            .country-list {
              height: 250px;
            }

            .selected-flag {
              .flag {
                // width: 20px;
                // height: 20px;
                // border-radius: 50%;
                // object-fit: cover;
                // margin-top: -12px;

                .arrow {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
  &__center-text {
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
    z-index: 4;
    max-width: 1300px;
    width: 100%;
    @media screen and (max-width: $br-xxl) {
      max-width: 92%;
    }
  }
  &__bg-wrap {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: linear-gradient(
      353.83deg,
      #0c0c0c 53.23%,
      rgba(12, 12, 12, 0) 89.29%
    );
  }
}

.rounded {
  &__20 {
    border-radius: 20px;
  }
}

.hr {
  &__line-modal {
    border-top: 1px solid #ffffff1f;
  }
  &__home-line {
    border-top: 1px solid #ffffff29;
  }
}

.list {
}

.images {
  &__bg-wrap {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }
  &__cover {
    &-1 {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      z-index: 2;
    }
    &-2 {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 0;
      width: 150rem;
      z-index: 1;
      max-width: 240%;
      background-blend-mode: luminosity;
      opacity: 0.8;
      filter: blur(20px);
    }
  }
}
.py {
  &__80 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

.path {
}

.btn {
  &__select {
    border-radius: 20px;
    background-color: #1d4049;
    color: color("gray-2") !important;

    &.active {
      background-color: color("tosca");
      color: color("black") !important;
    }
  }
}

.pt {
  &__96 {
    padding-top: 96px;
    @media screen and (max-width: $br-md) {
      padding-top: 69px;
    }
  }

  &__50 {
    padding-top: 50px;

    @media screen and (max-width: $br-md) {
      padding-top: 90px;
    }
  }
}

.mb {
  &__4 {
    margin-bottom: 4rem;
  }
}

.mt {
  &__vh {
    &-30 {
      margin-top: 30vh !important;

      @media screen and (max-width: $br-md) {
        margin-top: 1rem !important;
      }
    }
  }

  &__sm {
    &-4 {
      @media screen and (max-width: $br-sm) {
        margin-top: 4rem;
      }
    }
  }

  &-min {
    &-20 {
      margin-top: -20rem;

      &.no__md {
        @media screen and (max-width: $br-md) {
          margin-top: initial;
        }
      }
    }
    &-6 {
      margin-top: -6rem;

      @media screen and (max-width: 1024px) {
        margin-top: -5rem;
      }

      @media screen and (max-width: $br-sm) {
        margin-top: -4rem;
      }

      @media screen and (max-width: $br-mm) {
        margin-top: -3rem;
      }

      @media screen and (max-width: $br-xx) {
        margin-top: -2.5rem;
      }
    }
  }

  &__5 {
    margin-top: 5rem;

    &.res {
      @media screen and (max-width: $br-sm) {
        margin-top: 3rem;
      }
    }
  }

  &__7 {
    margin-top: 7rem;
  }

  &__10 {
    margin-top: 10rem;
  }

  &__12 {
    margin-top: 12rem;
  }

  &__20 {
    margin-top: 20rem;

    &.sm-no {
      @media screen and (max-width: $br-sm) {
        margin-top: 0;
      }
    }
  }

  &__30 {
    margin-top: 30rem;

    @media screen and (max-width: $br-md) {
      margin-top: 17rem;
    }
  }

  &-13 {
    margin-top: 13rem;
  }

  &-6 {
    margin-top: 6rem;

    &.mt__no-sm {
      @media screen and (max-width: $br-sm) {
        margin-top: 0;
      }
    }
  }

  &-10 {
    margin-top: 10rem;
  }

  &-35 {
    margin-top: 35rem;

    @media screen and (max-width: $br-md) {
      margin-top: 24rem;
    }
  }
}

// typography

.text {
  &__163 {
    &-1024 {
      @media screen and (max-width: 1024px) {
        font-size: 100px !important;
      }
    }

    &-md {
      @media screen and (max-width: $br-md) {
        font-size: 90px !important;
      }
    }

    &-sm {
      @media screen and (max-width: $br-sm) {
        font-size: 65px !important;
      }
    }

    &-xs {
      @media screen and (max-width: $br-xs) {
        font-size: 50px !important;
      }
    }
  }

  &__100 {
    &-1024 {
      @media screen and (max-width: 1024px) {
        font-size: 80px !important;
      }
    }

    &-md {
      @media screen and (max-width: $br-md) {
        font-size: 70px !important;
      }
    }

    &-sm {
      @media screen and (max-width: $br-sm) {
        font-size: 60px !important;
      }
    }

    &-mm {
      @media screen and (max-width: $br-mm) {
        font-size: 50px !important;
      }
    }

    &-xx {
      @media screen and (max-width: $br-xx) {
        font-size: 45px !important;
      }
    }

    &-xxs {
      @media screen and (max-width: $br-xxs) {
        font-size: 40px !important;
      }
    }
  }

  &__200 {
    &-md {
      @media screen and (max-width: $br-md) {
        font-size: 150px !important;
      }
    }

    &-sm {
      @media screen and (max-width: $br-sm) {
        font-size: 125px !important;
      }
    }

    &-sm {
      @media screen and (max-width: $br-mm) {
        font-size: 100px !important;
      }
    }

    &-xx {
      @media screen and (max-width: $br-xx) {
        font-size: 85px !important;
      }
    }

    &-xxs {
      @media screen and (max-width: $br-xxs) {
        font-size: 75px !important;
      }
    }
  }

  &__77 {
    &-sm {
      @media screen and (max-width: $br-sm) {
        font-size: 50px !important;
      }
    }
  }

  &__80 {
    &-1024 {
      @media screen and (max-width: 1024px) {
        font-size: 55px !important;
      }
    }

    &-md {
      @media screen and (max-width: $br-md) {
        font-size: 65px !important;
      }
    }

    &-sm {
      @media screen and (max-width: $br-sm) {
        font-size: 50px !important;
      }
    }

    &-sm {
      @media screen and (max-width: $br-xs) {
        font-size: 40px !important;
      }
    }
  }

  &__70 {
    &-1024 {
      @media screen and (max-width: 1024px) {
        font-size: 60px !important;
      }
    }

    &-md {
      @media screen and (max-width: $br-md) {
        font-size: 50px !important;
      }
    }

    &-sm {
      @media screen and (max-width: $br-sm) {
        font-size: 40px !important;
      }
    }

    &-xs {
      @media screen and (max-width: $br-xs) {
        font-size: 40px !important;
      }
    }

    &-xxs {
      @media screen and (max-width: $br-xxs) {
        font-size: 35px !important;
      }
    }
  }

  &__65 {
    &-1024 {
      @media screen and (max-width: 1024px) {
        font-size: 45px !important;
      }
    }

    &-sm {
      @media screen and (max-width: $br-sm) {
        font-size: 35px !important;
      }
    }
  }

  &__60 {
    &-1024 {
      @media screen and (max-width: 1024px) {
        font-size: 50px !important;
      }
    }

    &-sm {
      @media screen and (max-width: $br-sm) {
        font-size: 35px !important;
      }
    }

    &-xxs {
      @media screen and (max-width: $br-xxs) {
        font-size: 30px !important;
      }
    }
  }

  &__50 {
    &-1024 {
      @media screen and (max-width: 1024px) {
        font-size: 40px !important;
      }
    }

    &-sm {
      @media screen and (max-width: $br-sm) {
        font-size: 35px !important;
      }
    }

    &-xxs {
      @media screen and (max-width: $br-xxs) {
        font-size: 30px !important;
      }

      &-28 {
        @media screen and (max-width: $br-xxs) {
          font-size: 28px !important;
        }
      }
      &-20 {
        @media screen and (max-width: $br-xxs) {
          font-size: 20px !important;
        }
      }
    }
  }

  &__25 {
    &-sm {
      @media screen and (max-width: $br-sm) {
        font-size: 20px !important;
      }
    }
  }

  &__45 {
    &-1024 {
      @media screen and (max-width: 1024px) {
        font-size: 35px !important;
      }
    }

    &-md {
      @media screen and (max-width: $br-md) {
        font-size: 30px !important;
      }
    }

    &-mm {
      @media screen and (max-width: $br-mm) {
        font-size: 24px !important;
      }
    }
  }

  &__40 {
    &-1024 {
      @media screen and (max-width: 1024px) {
        font-size: 30px !important;
      }
    }

    &-md {
      @media screen and (max-width: $br-md) {
        font-size: 30px !important;
      }
    }

    &-mm {
      @media screen and (max-width: $br-mm) {
        font-size: 24px !important;
      }
    }
  }

  &__30 {
    &-1024 {
      @media screen and (max-width: 1024px) {
        font-size: 25px !important;
      }
    }

    &-md {
      @media screen and (max-width: $br-md) {
        font-size: 25px !important;
      }
    }

    &-sm {
      @media screen and (max-width: $br-sm) {
        font-size: 25px !important;
      }
    }

    &-xx {
      @media screen and (max-width: $br-xx) {
        font-size: 20px !important;
      }
    }
  }

  &__32 {
    &-1024 {
      @media screen and (max-width: 1024px) {
        font-size: 25px !important;
      }
    }

    &-xs {
      @media screen and (max-width: $br-xs) {
        font-size: 18px !important;
      }
    }
  }

  &__36 {
    &-1024 {
      @media screen and (max-width: 1024px) {
        font-size: 28px !important;
      }
    }
  }

  &__28 {
    &-1024 {
      @media screen and (max-width: 1024px) {
        font-size: 20px !important;
      }
    }

    &-sm {
      @media screen and (max-width: $br-sm) {
        font-size: 20px !important;
      }
    }
  }

  &__35 {
    &-1024 {
      @media screen and (max-width: 1024px) {
        font-size: 28px !important;
      }
    }

    &-md {
      @media screen and (max-width: $br-md) {
        font-size: 20px !important;
      }
    }

    &-xs {
      @media screen and (max-width: $br-xs) {
        font-size: 20px !important;
      }
    }
  }

  &__38 {
    &-1024 {
      @media screen and (max-width: 1024px) {
        font-size: 30px !important;
      }
    }
  }

  &__90 {
    &-1024 {
      @media screen and (max-width: 1024px) {
        font-size: 80px !important;
      }
    }

    &-md {
      @media screen and (max-width: $br-md) {
        font-size: 70px !important;
      }
    }

    &-sm {
      @media screen and (max-width: $br-sm) {
        font-size: 55px !important;
      }
    }

    &-xs {
      @media screen and (max-width: $br-xs) {
        font-size: 40px !important;
      }
    }

    &-xxs {
      @media screen and (max-width: $br-xs) {
        font-size: 38px !important;
      }
    }
  }

  &__33 {
    &-sm {
      @media screen and (max-width: $br-sm) {
        font-size: 25px !important;
      }
    }

    &-xx {
      @media screen and (max-width: $br-xx) {
        font-size: 18px !important;
      }
    }
  }

  &__18 {
    &-1024 {
      @media screen and (max-width: 1024px) {
        font-size: 16px !important;
      }
    }

    &-sm {
      @media screen and (max-width: $br-sm) {
        font-size: 16px !important;
      }
    }

    &-xs {
      @media screen and (max-width: $br-xs) {
        font-size: 14px !important;
      }
    }

    &-xxs {
      @media screen and (max-width: $br-xxs) {
        font-size: 12px !important;
      }
    }
  }

  &__16 {
    &-1024 {
      @media screen and (max-width: 1024px) {
        font-size: 14px !important;
      }

      &-12 {
        @media screen and (max-width: 1024px) {
          font-size: 12px !important;
        }
      }

      &-10 {
        @media screen and (max-width: 1024px) {
          font-size: 10px !important;
        }
      }
    }

    &-md {
      @media screen and (max-width: $br-md) {
        font-size: 12px !important;
      }
    }

    &-sm {
      @media screen and (max-width: $br-sm) {
        font-size: 12px !important;
      }
    }

    &-mm {
      @media screen and (max-width: $br-mm) {
        font-size: 10px !important;
      }

      &-12 {
        @media screen and (max-width: $br-mm) {
          font-size: 12px !important;
        }
      }
    }

    &-xx {
      @media screen and (max-width: $br-xx) {
        font-size: 10px !important;
      }
    }
  }

  &__20 {
    &-1024 {
      @media screen and (max-width: 1024px) {
        font-size: 16px !important;
      }
    }

    &-sm {
      @media screen and (max-width: $br-sm) {
        font-size: 14px !important;
      }
    }
  }

  &__120 {
    &-1024 {
      @media screen and (max-width: 1024px) {
        font-size: 100px !important;
      }
    }

    &-md {
      @media screen and (max-width: $br-md) {
        font-size: 69px !important;
      }
    }

    &-sm {
      @media screen and (max-width: $br-sm) {
        font-size: 60px !important;
      }
    }

    &-xxs {
      @media screen and (max-width: $br-xxs) {
        font-size: 50px !important;
      }
    }
  }

  &__26 {
    &-md {
      @media screen and (max-width: $br-md) {
        font-size: 20px !important;
      }
    }

    &-sm {
      @media screen and (max-width: $br-sm) {
        font-size: 14px !important;
      }
    }
  }

  &__22 {
    &-md {
      @media screen and (max-width: $br-md) {
        font-size: 16px !important;
      }
    }

    &-sm {
      @media screen and (max-width: $br-sm) {
        font-size: 14px !important;
      }
    }
  }

  &__12 {
    &-md {
      @media screen and (max-width: $br-md) {
        font-size: 10px !important;
      }
    }

    &-sm {
      @media screen and (max-width: $br-sm) {
        font-size: 10px !important;
      }

      &-8 {
        @media screen and (max-width: $br-sm) {
          font-size: 8px !important;
        }
      }
    }

    &-xs {
      @media screen and (max-width: $br-xs) {
        font-size: 8px !important;
      }

      &-10 {
        @media screen and (max-width: $br-xs) {
          font-size: 10px !important;
        }
      }
    }

    &-mm {
      @media screen and (max-width: $br-mm) {
        font-size: 8px !important;
      }

      &-10 {
        @media screen and (max-width: $br-mm) {
          font-size: 10px !important;
        }
      }
    }
  }

  &__14 {
    &-1024 {
      @media screen and (max-width: 1024px) {
        font-size: 12px !important;
      }

      &-10 {
        @media screen and (max-width: 1024px) {
          font-size: 10px !important;
        }
      }
    }

    &-md {
      @media screen and (max-width: $br-md) {
        font-size: 12px !important;
      }
    }

    &-sm {
      &-8 {
        @media screen and (max-width: $br-sm) {
          font-size: 8px !important;
        }
      }
    }

    &-mm {
      @media screen and (max-width: $br-mm) {
        font-size: 9px !important;
      }

      &-10 {
        @media screen and (max-width: $br-mm) {
          font-size: 10px !important;
        }
      }
    }

    &-xx {
      @media screen and (max-width: $br-xx) {
        font-size: 10px !important;
      }

      &-8 {
        @media screen and (max-width: $br-xx) {
          font-size: 8px !important;
        }
      }
    }
  }

  &__27 {
    &-1024 {
      @media screen and (max-width: 1024px) {
        font-size: 18px !important;
      }
    }
  }

  &__24 {
    &-1024 {
      @media screen and (max-width: 1024px) {
        font-size: 18px !important;
      }
    }

    &-sm {
      @media screen and (max-width: $br-sm) {
        font-size: 16px !important;
      }
    }
  }

  &__25 {
    &-1024 {
      @media screen and (max-width: 1024px) {
        font-size: 20px !important;
      }
    }
  }

  &__36 {
    &-1024 {
      @media screen and (max-width: 1024px) {
        font-size: 25px !important;
      }
    }

    &-sm {
      @media screen and (max-width: $br-sm) {
        font-size: 30px !important;
      }
    }
  }

  &__58 {
    &-sm {
      @media screen and (max-width: $br-sm) {
        font-size: 45px !important;
      }
    }
  }

  &__48 {
    &-1024 {
      @media screen and (max-width: 1024px) {
        font-size: 35px !important;
      }
    }

    &-md {
      @media screen and (max-width: $br-md) {
        font-size: 40px !important;
      }
    }

    &-sm {
      @media screen and (max-width: $br-sm) {
        font-size: 30px !important;
      }
    }
  }
}

.wrap {
  &__flex {
    &-1024 {
      &-100 {
        @media screen and (max-width: 1024px) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and (max-width: 1024px) {
            flex: 0 0 100% !important;
            max-width: 100% !important;
          }
        }
      }

      &-50 {
        @media screen and (max-width: 1024px) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and (max-width: 1024px) {
            flex: 0 0 45%;
            max-width: 45%;
          }
        }
      }

      &-90 {
        &-3 {
          @media screen and (max-width: 1024px) {
            flex-flow: wrap;
          }
          > .grid {
            @media screen and (max-width: 1024px) {
              flex: 0 0 calc(90% / 3);
              max-width: calc(90% / 3);
            }
          }
        }
      }

      &-auto {
        @media screen and (max-width: 1024px) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and (max-width: 1024px) {
            flex: 0 0 auto !important;
            max-width: inherit !important;
          }
        }
      }
    }

    &-md {
      &-100 {
        @media screen and (max-width: $br-md) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and (max-width: $br-md) {
            flex: 0 0 100%;
            max-width: 100%;
          }
        }
      }

      &-50 {
        @media screen and (max-width: $br-md) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and (max-width: $br-md) {
            flex: 0 0 45%;
            max-width: 45%;
          }
        }
      }

      &-auto {
        @media screen and (max-width: $br-md) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and (max-width: $br-md) {
            flex: 0 0 auto !important;
            max-width: inherit !important;
          }
        }
      }
    }

    &-sm {
      &-100 {
        @media screen and (max-width: $br-sm) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and (max-width: $br-sm) {
            flex: 0 0 100%;
            max-width: 100%;
          }
        }
      }

      &-50 {
        @media screen and (max-width: $br-sm) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and (max-width: $br-sm) {
            flex: 0 0 45%;
            max-width: 45%;
          }
        }
      }
    }

    &-xs {
      &-100 {
        @media screen and (max-width: $br-xs) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and (max-width: $br-xs) {
            flex: 0 0 100%;
            max-width: 100%;
          }
        }
      }

      &-50 {
        @media screen and (max-width: $br-sm) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and (max-width: $br-sm) {
            flex: 0 0 45%;
            max-width: 45%;
          }
        }
      }
    }

    &-mm {
      &-100 {
        @media screen and (max-width: $br-mm) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and (max-width: $br-mm) {
            flex: 0 0 100%;
            max-width: 100%;
          }
        }
      }

      &-50 {
        @media screen and (max-width: $br-sm) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and (max-width: $br-sm) {
            flex: 0 0 45%;
            max-width: 45%;
          }
        }
      }

      &-4 {
        @media screen and (max-width: $br-mm) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and (max-width: $br-mm) {
            flex: 0 0 calc(100% / 6);
            max-width: calc(100% / 6);
          }
        }
      }
    }
  }
}

// responsive font
.font__size {
  &--24 {
    @media #{$mq-xlarge} {
      font-size: 1.5em * 1.4 !important;
    }
    @media #{$mq-xxlarge} {
      font-size: 1.5em * 1.6 !important;
    }
  }
  &--20 {
    @media #{$mq-xlarge} {
      font-size: 1.9em !important;
    }
    @media #{$mq-xxlarge} {
      font-size: 2.5em !important;
    }
  }
  &--64 {
    @media #{$mq-xxlarge} {
      font-size: 72px !important;
    }
  }
  &--16 {
    @media #{$mq-xlarge} {
      font-size: 1em * 1.4 !important;
    }
    @media #{$mq-xxlarge} {
      font-size: 1em * 1.6 !important;
    }
  }
  &--14 {
    @media #{$mq-xlarge} {
      font-size: 0.87em * 1.4 !important;
    }
    @media #{$mq-xxlarge} {
      font-size: 0.87em * 1.6 !important;
    }
  }
  &--96 {
    @media #{$mq-xlarge} {
      font-size: 8.18em !important;
    }
    @media #{$mq-xxlarge} {
      font-size: 10em !important;
    }
  }
}
