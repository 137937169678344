$colors: (
  "white": #ffffff,
  "black": #082A3A,
  "black-2": #1A1A1A,
  "black-3": #000F11,
  "tosca": #00E6F6,
  "red": #E93527,
  "transparent": transparent,
  "gray-1": #959595,
  "gray-2": #BCC6C8,
  "gray-3": #464F51,
);

// Default Font Size
$base-font-size: 16px;

$base-url: "http://localhost:3000/";

// Responsive Breakpoint
$br-xxs: 375px;
$br-xx: 425px;
$br-xs: 480px;
$br-mm: 512px;
$br-sm: 576px;
$br-md: 850px;
$br-lg: 992px;
$br-xl: 1140px;
$br-xxl: 1200px;

// large
// Breakpoints
$bp-small: 48em; // 768px
$bp-medium: 64em; // 1024px
$bp-large: 85.375em; // 1366px
$bp-xlarge: 120em; // 1920px
$bp-xxlarge: 160em; // 2560px

// Media Queries
$mq-small: "(min-width: #{$bp-small})";
$mq-medium: "(min-width: #{$bp-medium})";
$mq-large: "(min-width: #{$bp-large})";
$mq-xlarge: "(min-width: #{$bp-xlarge})";
$mq-xxlarge: "(min-width: #{$bp-xxlarge})";
$mq-retina: "(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)";

$base-font-size-large: 1em;
