@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@keyframes zoom {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3, 1.3);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes floating {
  0% {
    transform: translateX(-50%) translateY(0%);
  }
  50% {
    transform: translateX(-50%) translateY(8%);
  }
  100% {
    transform: translateX(-50%) translateY(0%);
  }
}
@keyframes floatingY {
  0% {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(8%);
  }
  100% {
    transform: translateY(0%);
  }
}
@keyframes cliprotate {
  0% {
    transform: rotate(0deg);
    border-top-color: white;
  }
  25% {
    transform: rotate(90deg);
    border-top-color: white;
    border-right-color: white;
  }
  75% {
    transform: rotate(180deg);
    border-top-color: white;
    border-right-color: white;
    border-bottom-color: white;
  }
  100% {
    transform: rotate(360deg);
    border-top-color: white;
    border-right-color: white;
    border-bottom-color: white;
    border-left-color: white;
  }
}
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes orbit {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(-360deg);
  }
}
@keyframes orbit-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
@keyframes orbit-reverse {
  0% {
    transform: rotateZ(-3600deg);
  }
  100% {
    transform: rotateZ(0deg);
  }
}
@keyframes bgScrollLeft {
  from {
    background-position: 100% 0;
  }
  to {
    background-position: 100% -230px;
  }
}
@keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 #ffffff;
    box-shadow: 0 0 0 0 #ffffff;
  }
  70% {
    -webkit-box-shadow: 0 0 0 40px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 40px rgba(255, 255, 255, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}
@keyframes action-bounce {
  0%, 100% {
    transform: translate(0, 0);
  }
  10% {
    transform: translate(0, 4px);
  }
  50% {
    transform: translate(0, -15px);
  }
  90% {
    transform: translate(0, 4px);
  }
}
@keyframes action-jelly {
  0%, 100% {
    transform: scaleX(1);
  }
  20% {
    transform: scaleX(0.9);
  }
  50% {
    transform: scaleX(1.25);
  }
  85% {
    transform: scaleX(0.8);
  }
}
@keyframes jello {
  11.1% {
    transform: none;
  }
  22.2% {
    transform: skewX(-12.5deg) skewY(-12.5deg);
  }
  33.3% {
    transform: skewX(6.25deg) skewY(6.25deg);
  }
  44.4% {
    transform: skewX(-3.125deg) skewY(-3.125deg);
  }
  55.5% {
    transform: skewX(1.5625deg) skewY(1.5625deg);
  }
  66.6% {
    transform: skewX(-0.78125deg) skewY(-0.78125deg);
  }
  77.7% {
    transform: skewX(0.390625deg) skewY(0.390625deg);
  }
  88.8% {
    transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
  }
  100% {
    transform: none;
  }
}
@keyframes jumping {
  0% {
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px);
  }
  70% {
    -webkit-transform: scale(1.02, 0.9) translateY(10px);
    transform: scale(1.02, 0.9) translateY(10px);
  }
  100% {
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px);
  }
}
@keyframes jumping-rotate {
  0% {
    -webkit-transform: translateY(-40px) rotate(45deg);
    transform: translateY(-40px) rotate(45deg);
  }
  70% {
    -webkit-transform: scale(1.02, 0.9) translateY(10px) rotate(45deg);
    transform: scale(1.02, 0.9) translateY(10px) rotate(45deg);
  }
  100% {
    -webkit-transform: translateY(-40px) rotate(45deg);
    transform: translateY(-40px) rotate(45deg);
  }
}
@keyframes boxWithShadow {
  0% {
    width: 80%;
    opacity: 1;
  }
  50% {
    width: 50%;
    opacity: 0.5;
  }
  to {
    width: 80%;
    opacity: 1;
  }
}
@keyframes bounceDown-on {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-8px);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes bounceRight-on {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-8px);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes bounceDown {
  0% {
    transform: translateY(0) scale(0.88);
  }
  50% {
    transform: translateY(-5rem) scale(0.88);
  }
  to {
    transform: translateY(0) scale(0.88);
  }
}
@keyframes shadowRegulator {
  0% {
    width: 80%;
    opacity: 1;
  }
  50% {
    width: 50%;
    opacity: 0.5;
  }
  to {
    width: 80%;
    opacity: 1;
  }
}
@keyframes upDown {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-1rem);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes upDown2 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-0.5rem);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes upDown-totate {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-1rem);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes scale1 {
  0% {
    transform: scale(1) rotate(0deg);
  }
  50% {
    transform: scale(1.1) rotate(360deg);
  }
  100% {
    transform: scale(1) rotate(0deg);
  }
}
@keyframes roate1 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes roate1rivers {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}
@keyframes scaleImage {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes backForward {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(25%);
  }
  100% {
    transform: translateX(0%);
  }
}
@keyframes leftTop {
  from {
    opacity: 0;
    transform: rotate(360deg);
  }
  to {
    opacity: 1;
    transform: rotate(0deg);
  }
}
@keyframes leftBottom {
  from {
    opacity: 0;
    transform: rotate(-360deg);
  }
  to {
    opacity: 1;
    transform: rotate(0deg);
  }
}
@keyframes rightTop {
  from {
    opacity: 0;
    transform: rotate(-100deg);
  }
  to {
    opacity: 1;
    transform: rotate(0deg);
  }
}
@keyframes rightBottom {
  from {
    opacity: 0;
    transform: rotate(100deg);
  }
  to {
    opacity: 1;
    transform: rotate(0deg);
  }
}
@keyframes blurAnimate {
  0% {
    filter: blur(10px);
  }
  50% {
    filter: blur(5px);
  }
  100% {
    filter: blur(10px);
  }
}
@keyframes rightLeft {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(45deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
.inter {
  font-family: "Inter", sans-serif;
}

h1 {
  font-size: 70px;
}

h2 {
  font-size: 50px;
}
@media screen and (max-width: 850px) {
  h2 {
    font-size: 35px;
  }
}

h3 {
  font-size: 40px;
}
@media screen and (max-width: 850px) {
  h3 {
    font-size: 25px;
  }
}

h4 {
  font-size: 30px;
}
@media screen and (max-width: 850px) {
  h4 {
    font-size: 20px;
  }
}

h5 {
  font-size: 24px;
}
@media screen and (max-width: 850px) {
  h5 {
    font-size: 18px;
  }
}

h6 {
  font-size: 18px;
}
@media screen and (max-width: 850px) {
  h6 {
    font-size: 14px;
  }
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 900;
}

.thin {
  font-weight: 100 !important;
}

.extra-light {
  font-weight: 200 !important;
}

.light {
  font-weight: 300 !important;
}

.regular {
  font-weight: 400 !important;
}

.medium {
  font-weight: 500 !important;
}

.semi-bold {
  font-weight: 600 !important;
}

.bold {
  font-weight: 700 !important;
}

.extra-bold {
  font-weight: 800 !important;
}

.black {
  font-weight: 900 !important;
}

.normal {
  font-weight: normal !important;
}

.uppercase__font {
  text-transform: uppercase;
}

.capitalize__font {
  text-transform: capitalize;
}

.justify {
  text-align: justify;
}

.underline {
  text-decoration: underline;
}

@font-face {
  font-family: circular;
  src: url("../fonts/CircularStd-Book.ttf");
}
.circular {
  font-family: circular;
}

body {
  font-family: "Inter", sans-serif;
  color: #ffffff;
  font-size: 16px;
  letter-spacing: 0.03em;
  line-height: 1.7em;
}

.decoration {
  text-decoration: underline;
}
.decoration:hover {
  text-decoration: underline;
}

a {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}

img {
  max-width: 100%;
}

section {
  padding: 30px 0;
}

.container {
  max-width: 1300px;
}
@media screen and (max-width: 1200px) {
  .container {
    max-width: 92%;
  }
}
@media (min-width: 120em) {
  .container {
    max-width: 1600px;
  }
}
@media (min-width: 160em) {
  .container {
    max-width: 2000px;
  }
}

.divider {
  height: 30px;
}
.divider__xs {
  height: 10px;
}
.divider__sm {
  height: 20px;
}
.divider__md {
  height: 40px;
}
.divider__lg {
  height: 60px;
}
.divider__xlg {
  height: 80px;
}
.divider__xxlg {
  height: 100px;
}

.modal-content {
  border: 0;
  padding: 40px;
  border-radius: 10px;
}
.modal-content.send-mail {
  padding: 20px;
}

.tooltip {
  border-radius: 10px;
}
.tooltip > .tooltip-inner {
  padding: 8px 15px;
  font-weight: 500;
  font-family: "Lato";
  color: #ffffff !important;
  letter-spacing: 0.01em;
  font-size: 13px;
  background-color: #082A3A !important;
}

.bs-tooltip-auto[x-placement^=bottom] .arrow::before,
.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #082A3A !important;
}

.bs-tooltip-auto[x-placement^=top] .arrow::before,
.bs-tooltip-top .arrow::before {
  border-top-color: #082A3A !important;
}

.bs-tooltip-auto[x-placement^=left] .arrow::before,
.bs-tooltip-left .arrow::before {
  border-left-color: #082A3A !important;
}

.bs-tooltip-auto[x-placement^=right] .arrow::before,
.bs-tooltip-right .arrow::before {
  border-right-color: #082A3A !important;
}

.pointer {
  cursor: pointer;
}

.pointer__default {
  cursor: default;
}

.no__outline {
  outline-style: none !important;
}
.no__outline:focus {
  outline-style: none !important;
}

.modal {
  background-color: rgba(219, 223, 241, 0.6) !important;
}

.width__full {
  width: 100%;
}

.max-width-1140 {
  max-width: 1140px;
}
@media screen and (max-width: 576px) {
  .max-width-1140 {
    max-width: 92%;
  }
}

.object-fit-content {
  object-fit: contain !important;
}

.height-100 {
  height: 100vh;
}

.min-height-100 {
  min-height: 100vh;
}
.min-height-100.margin-100 {
  margin-top: 100px;
  min-height: calc(100vh - 100px);
}

.max-height-100 {
  max-height: 100vh;
}

.no__maxwid {
  max-width: 100% !important;
}

.fit-content {
  max-width: fit-content !important;
}

@media screen and (max-width: 576px) {
  .no__xs-fit {
    max-width: inherit !important;
  }
}

.z-1 {
  z-index: 1;
}

.z-2 {
  z-index: 2;
}

.z-3 {
  z-index: 3;
}

.z-4 {
  z-index: 4;
}

.z-5 {
  z-index: 5;
}

.ul__nostyle {
  margin: 0;
  padding: 0;
  list-style: none;
}

@media screen and (min-width: 1440px) {
  .max-1440-up {
    max-width: 1440px;
    margin: 0 auto;
  }
}

.lh-2 {
  line-height: 2 !important;
}

.mt-min-10 {
  margin-top: -10px;
}

.img-content {
  object-fit: contain;
}

.pb-80px {
  padding-bottom: 80px;
}

.bg__white {
  background-color: #ffffff !important;
}

.bg__black {
  background-color: #082A3A !important;
}

.bg__black-2 {
  background-color: #1A1A1A !important;
}

.bg__black-3 {
  background-color: #000F11 !important;
}

.bg__tosca {
  background-color: #00E6F6 !important;
}

.bg__red {
  background-color: #E93527 !important;
}

.bg__transparent {
  background-color: transparent !important;
}

.bg__gray-1 {
  background-color: #959595 !important;
}

.bg__gray-2 {
  background-color: #BCC6C8 !important;
}

.bg__gray-3 {
  background-color: #464F51 !important;
}

.opacity__1 {
  opacity: 0.1;
  transition: 0.5s;
}
.opacity__1.hover:hover {
  opacity: 1;
}

.opacity__2 {
  opacity: 0.2;
  transition: 0.5s;
}
.opacity__2.hover:hover {
  opacity: 1;
}

.opacity__3 {
  opacity: 0.3;
  transition: 0.5s;
}
.opacity__3.hover:hover {
  opacity: 1;
}

.opacity__4 {
  opacity: 0.4;
  transition: 0.5s;
}
.opacity__4.hover:hover {
  opacity: 1;
}

.opacity__5 {
  opacity: 0.5;
  transition: 0.5s;
}
.opacity__5.hover:hover {
  opacity: 1;
}

.opacity__6 {
  opacity: 0.6;
  transition: 0.5s;
}
.opacity__6.hover:hover {
  opacity: 1;
}

.opacity__7 {
  opacity: 0.7;
  transition: 0.5s;
}
.opacity__7.hover:hover {
  opacity: 1;
}

.opacity__8 {
  opacity: 0.8;
  transition: 0.5s;
}
.opacity__8.hover:hover {
  opacity: 1;
}

.opacity__9 {
  opacity: 0.9;
  transition: 0.5s;
}
.opacity__9.hover:hover {
  opacity: 1;
}

.opacity__10 {
  opacity: 1;
  transition: 0.5s;
}
.opacity__10.hover:hover {
  opacity: 1;
}

.border__radius--0 {
  border-radius: 0px !important;
}

.border__radius--1 {
  border-radius: 1px !important;
}

.border__radius--2 {
  border-radius: 2px !important;
}

.border__radius--3 {
  border-radius: 3px !important;
}

.border__radius--4 {
  border-radius: 4px !important;
}

.border__radius--5 {
  border-radius: 5px !important;
}

.border__radius--6 {
  border-radius: 6px !important;
}

.border__radius--7 {
  border-radius: 7px !important;
}

.border__radius--8 {
  border-radius: 8px !important;
}

.border__radius--9 {
  border-radius: 9px !important;
}

.border__radius--10 {
  border-radius: 10px !important;
}

.border__radius--11 {
  border-radius: 11px !important;
}

.border__radius--12 {
  border-radius: 12px !important;
}

.border__radius--13 {
  border-radius: 13px !important;
}

.border__radius--14 {
  border-radius: 14px !important;
}

.border__radius--15 {
  border-radius: 15px !important;
}

.border__radius--16 {
  border-radius: 16px !important;
}

.border__radius--17 {
  border-radius: 17px !important;
}

.border__radius--18 {
  border-radius: 18px !important;
}

.border__radius--19 {
  border-radius: 19px !important;
}

.border__radius--20 {
  border-radius: 20px !important;
}

.border__radius--21 {
  border-radius: 21px !important;
}

.border__radius--22 {
  border-radius: 22px !important;
}

.border__radius--23 {
  border-radius: 23px !important;
}

.border__radius--24 {
  border-radius: 24px !important;
}

.border__radius--25 {
  border-radius: 25px !important;
}

.border__radius--26 {
  border-radius: 26px !important;
}

.border__radius--27 {
  border-radius: 27px !important;
}

.border__radius--28 {
  border-radius: 28px !important;
}

.border__radius--29 {
  border-radius: 29px !important;
}

.border__radius--30 {
  border-radius: 30px !important;
}

.border__radius--31 {
  border-radius: 31px !important;
}

.border__radius--32 {
  border-radius: 32px !important;
}

.border__radius--33 {
  border-radius: 33px !important;
}

.border__radius--34 {
  border-radius: 34px !important;
}

.border__radius--35 {
  border-radius: 35px !important;
}

.border__radius--36 {
  border-radius: 36px !important;
}

.border__radius--37 {
  border-radius: 37px !important;
}

.border__radius--38 {
  border-radius: 38px !important;
}

.border__radius--39 {
  border-radius: 39px !important;
}

.border__radius--40 {
  border-radius: 40px !important;
}

.border__radius--41 {
  border-radius: 41px !important;
}

.border__radius--42 {
  border-radius: 42px !important;
}

.border__radius--43 {
  border-radius: 43px !important;
}

.border__radius--44 {
  border-radius: 44px !important;
}

.border__radius--45 {
  border-radius: 45px !important;
}

.border__radius--46 {
  border-radius: 46px !important;
}

.border__radius--47 {
  border-radius: 47px !important;
}

.border__radius--48 {
  border-radius: 48px !important;
}

.border__radius--49 {
  border-radius: 49px !important;
}

.border__radius--50 {
  border-radius: 50px !important;
}

.color__white {
  color: #ffffff !important;
}

.color__black {
  color: #082A3A !important;
}

.color__black-2 {
  color: #1A1A1A !important;
}

.color__black-3 {
  color: #000F11 !important;
}

.color__tosca {
  color: #00E6F6 !important;
}

.color__red {
  color: #E93527 !important;
}

.color__transparent {
  color: transparent !important;
}

.color__gray-1 {
  color: #959595 !important;
}

.color__gray-2 {
  color: #BCC6C8 !important;
}

.color__gray-3 {
  color: #464F51 !important;
}

.fw__1 {
  font-weight: 100 !important;
}

.fw__2 {
  font-weight: 200 !important;
}

.fw__3 {
  font-weight: 300 !important;
}

.fw__4 {
  font-weight: 400 !important;
}

.fw__5 {
  font-weight: 500 !important;
}

.fw__6 {
  font-weight: 600 !important;
}

.fw__7 {
  font-weight: 700 !important;
}

.fw__8 {
  font-weight: 800 !important;
}

.lh__1 {
  line-height: 1.1 !important;
}

.lh__2 {
  line-height: 1.2 !important;
}

.lh__3 {
  line-height: 1.3 !important;
}

.lh__4 {
  line-height: 1.4 !important;
}

.lh__5 {
  line-height: 1.5 !important;
}

.lh__6 {
  line-height: 1.6 !important;
}

.lh__7 {
  line-height: 1.7 !important;
}

.lh__8 {
  line-height: 1.8 !important;
}

.lh__9 {
  line-height: 1.9 !important;
}

.ls__1 {
  letter-spacing: 0.2em !important;
}

.ls__2 {
  letter-spacing: 0.3em !important;
}

.ls__3 {
  letter-spacing: 0.4em !important;
}

.ls__4 {
  letter-spacing: 0.5em !important;
}

.ls__5 {
  letter-spacing: 0.6em !important;
}

.ls__6 {
  letter-spacing: 0.7em !important;
}

.ls__7 {
  letter-spacing: 0.8em !important;
}

.ls__8 {
  letter-spacing: 0.9em !important;
}

.ls__9 {
  letter-spacing: 1em !important;
}

.clamp__1 {
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.clamp__2 {
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.clamp__3 {
  display: -webkit-box !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.clamp__4 {
  display: -webkit-box !important;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.font__size--6 {
  font-size: 6px !important;
}

.font__size--7 {
  font-size: 7px !important;
}

.font__size--8 {
  font-size: 8px !important;
}

.font__size--9 {
  font-size: 9px !important;
}

.font__size--10 {
  font-size: 10px !important;
}

.font__size--11 {
  font-size: 11px !important;
}

.font__size--12 {
  font-size: 12px !important;
}

.font__size--13 {
  font-size: 13px !important;
}

.font__size--14 {
  font-size: 14px !important;
}

.font__size--15 {
  font-size: 15px !important;
}

.font__size--16 {
  font-size: 16px !important;
}

.font__size--17 {
  font-size: 17px !important;
}

.font__size--18 {
  font-size: 18px !important;
}

.font__size--19 {
  font-size: 19px !important;
}

.font__size--20 {
  font-size: 20px !important;
}

.font__size--21 {
  font-size: 21px !important;
}

.font__size--22 {
  font-size: 22px !important;
}

.font__size--23 {
  font-size: 23px !important;
}

.font__size--24 {
  font-size: 24px !important;
}

.font__size--25 {
  font-size: 25px !important;
}

.font__size--26 {
  font-size: 26px !important;
}

.font__size--27 {
  font-size: 27px !important;
}

.font__size--28 {
  font-size: 28px !important;
}

.font__size--29 {
  font-size: 29px !important;
}

.font__size--30 {
  font-size: 30px !important;
}

.font__size--31 {
  font-size: 31px !important;
}

.font__size--32 {
  font-size: 32px !important;
}

.font__size--33 {
  font-size: 33px !important;
}

.font__size--34 {
  font-size: 34px !important;
}

.font__size--35 {
  font-size: 35px !important;
}

.font__size--36 {
  font-size: 36px !important;
}

.font__size--37 {
  font-size: 37px !important;
}

.font__size--38 {
  font-size: 38px !important;
}

.font__size--39 {
  font-size: 39px !important;
}

.font__size--40 {
  font-size: 40px !important;
}

.font__size--41 {
  font-size: 41px !important;
}

.font__size--42 {
  font-size: 42px !important;
}

.font__size--43 {
  font-size: 43px !important;
}

.font__size--44 {
  font-size: 44px !important;
}

.font__size--45 {
  font-size: 45px !important;
}

.font__size--46 {
  font-size: 46px !important;
}

.font__size--47 {
  font-size: 47px !important;
}

.font__size--48 {
  font-size: 48px !important;
}

.font__size--49 {
  font-size: 49px !important;
}

.font__size--50 {
  font-size: 50px !important;
}

.font__size--51 {
  font-size: 51px !important;
}

.font__size--52 {
  font-size: 52px !important;
}

.font__size--53 {
  font-size: 53px !important;
}

.font__size--54 {
  font-size: 54px !important;
}

.font__size--55 {
  font-size: 55px !important;
}

.font__size--56 {
  font-size: 56px !important;
}

.font__size--57 {
  font-size: 57px !important;
}

.font__size--58 {
  font-size: 58px !important;
}

.font__size--59 {
  font-size: 59px !important;
}

.font__size--60 {
  font-size: 60px !important;
}

.font__size--61 {
  font-size: 61px !important;
}

.font__size--62 {
  font-size: 62px !important;
}

.font__size--63 {
  font-size: 63px !important;
}

.font__size--64 {
  font-size: 64px !important;
}

.font__size--65 {
  font-size: 65px !important;
}

.font__size--66 {
  font-size: 66px !important;
}

.font__size--67 {
  font-size: 67px !important;
}

.font__size--68 {
  font-size: 68px !important;
}

.font__size--69 {
  font-size: 69px !important;
}

.font__size--70 {
  font-size: 70px !important;
}

.font__size--71 {
  font-size: 71px !important;
}

.font__size--72 {
  font-size: 72px !important;
}

.font__size--73 {
  font-size: 73px !important;
}

.font__size--74 {
  font-size: 74px !important;
}

.font__size--75 {
  font-size: 75px !important;
}

.font__size--76 {
  font-size: 76px !important;
}

.font__size--77 {
  font-size: 77px !important;
}

.font__size--78 {
  font-size: 78px !important;
}

.font__size--79 {
  font-size: 79px !important;
}

.font__size--80 {
  font-size: 80px !important;
}

.font__size--81 {
  font-size: 81px !important;
}

.font__size--82 {
  font-size: 82px !important;
}

.font__size--83 {
  font-size: 83px !important;
}

.font__size--84 {
  font-size: 84px !important;
}

.font__size--85 {
  font-size: 85px !important;
}

.font__size--86 {
  font-size: 86px !important;
}

.font__size--87 {
  font-size: 87px !important;
}

.font__size--88 {
  font-size: 88px !important;
}

.font__size--89 {
  font-size: 89px !important;
}

.font__size--90 {
  font-size: 90px !important;
}

.font__size--91 {
  font-size: 91px !important;
}

.font__size--92 {
  font-size: 92px !important;
}

.font__size--93 {
  font-size: 93px !important;
}

.font__size--94 {
  font-size: 94px !important;
}

.font__size--95 {
  font-size: 95px !important;
}

.font__size--96 {
  font-size: 96px !important;
}

.font__size--97 {
  font-size: 97px !important;
}

.font__size--98 {
  font-size: 98px !important;
}

.font__size--99 {
  font-size: 99px !important;
}

.font__size--100 {
  font-size: 100px !important;
}

.font__size--101 {
  font-size: 101px !important;
}

.font__size--102 {
  font-size: 102px !important;
}

.font__size--103 {
  font-size: 103px !important;
}

.font__size--104 {
  font-size: 104px !important;
}

.font__size--105 {
  font-size: 105px !important;
}

.font__size--106 {
  font-size: 106px !important;
}

.font__size--107 {
  font-size: 107px !important;
}

.font__size--108 {
  font-size: 108px !important;
}

.font__size--109 {
  font-size: 109px !important;
}

.font__size--110 {
  font-size: 110px !important;
}

.font__size--111 {
  font-size: 111px !important;
}

.font__size--112 {
  font-size: 112px !important;
}

.font__size--113 {
  font-size: 113px !important;
}

.font__size--114 {
  font-size: 114px !important;
}

.font__size--115 {
  font-size: 115px !important;
}

.font__size--116 {
  font-size: 116px !important;
}

.font__size--117 {
  font-size: 117px !important;
}

.font__size--118 {
  font-size: 118px !important;
}

.font__size--119 {
  font-size: 119px !important;
}

.font__size--120 {
  font-size: 120px !important;
}

.font__size--121 {
  font-size: 121px !important;
}

.font__size--122 {
  font-size: 122px !important;
}

.font__size--123 {
  font-size: 123px !important;
}

.font__size--124 {
  font-size: 124px !important;
}

.font__size--125 {
  font-size: 125px !important;
}

.font__size--126 {
  font-size: 126px !important;
}

.font__size--127 {
  font-size: 127px !important;
}

.font__size--128 {
  font-size: 128px !important;
}

.font__size--129 {
  font-size: 129px !important;
}

.font__size--130 {
  font-size: 130px !important;
}

.font__size--131 {
  font-size: 131px !important;
}

.font__size--132 {
  font-size: 132px !important;
}

.font__size--133 {
  font-size: 133px !important;
}

.font__size--134 {
  font-size: 134px !important;
}

.font__size--135 {
  font-size: 135px !important;
}

.font__size--136 {
  font-size: 136px !important;
}

.font__size--137 {
  font-size: 137px !important;
}

.font__size--138 {
  font-size: 138px !important;
}

.font__size--139 {
  font-size: 139px !important;
}

.font__size--140 {
  font-size: 140px !important;
}

.font__size--141 {
  font-size: 141px !important;
}

.font__size--142 {
  font-size: 142px !important;
}

.font__size--143 {
  font-size: 143px !important;
}

.font__size--144 {
  font-size: 144px !important;
}

.font__size--145 {
  font-size: 145px !important;
}

.font__size--146 {
  font-size: 146px !important;
}

.font__size--147 {
  font-size: 147px !important;
}

.font__size--148 {
  font-size: 148px !important;
}

.font__size--149 {
  font-size: 149px !important;
}

.font__size--150 {
  font-size: 150px !important;
}

.font__size--151 {
  font-size: 151px !important;
}

.font__size--152 {
  font-size: 152px !important;
}

.font__size--153 {
  font-size: 153px !important;
}

.font__size--154 {
  font-size: 154px !important;
}

.font__size--155 {
  font-size: 155px !important;
}

.font__size--156 {
  font-size: 156px !important;
}

.font__size--157 {
  font-size: 157px !important;
}

.font__size--158 {
  font-size: 158px !important;
}

.font__size--159 {
  font-size: 159px !important;
}

.font__size--160 {
  font-size: 160px !important;
}

.font__size--161 {
  font-size: 161px !important;
}

.font__size--162 {
  font-size: 162px !important;
}

.font__size--163 {
  font-size: 163px !important;
}

.font__size--164 {
  font-size: 164px !important;
}

.font__size--165 {
  font-size: 165px !important;
}

.font__size--166 {
  font-size: 166px !important;
}

.font__size--167 {
  font-size: 167px !important;
}

.font__size--168 {
  font-size: 168px !important;
}

.font__size--169 {
  font-size: 169px !important;
}

.font__size--170 {
  font-size: 170px !important;
}

.font__size--171 {
  font-size: 171px !important;
}

.font__size--172 {
  font-size: 172px !important;
}

.font__size--173 {
  font-size: 173px !important;
}

.font__size--174 {
  font-size: 174px !important;
}

.font__size--175 {
  font-size: 175px !important;
}

.font__size--176 {
  font-size: 176px !important;
}

.font__size--177 {
  font-size: 177px !important;
}

.font__size--178 {
  font-size: 178px !important;
}

.font__size--179 {
  font-size: 179px !important;
}

.font__size--180 {
  font-size: 180px !important;
}

.font__size--181 {
  font-size: 181px !important;
}

.font__size--182 {
  font-size: 182px !important;
}

.font__size--183 {
  font-size: 183px !important;
}

.font__size--184 {
  font-size: 184px !important;
}

.font__size--185 {
  font-size: 185px !important;
}

.font__size--186 {
  font-size: 186px !important;
}

.font__size--187 {
  font-size: 187px !important;
}

.font__size--188 {
  font-size: 188px !important;
}

.font__size--189 {
  font-size: 189px !important;
}

.font__size--190 {
  font-size: 190px !important;
}

.font__size--191 {
  font-size: 191px !important;
}

.font__size--192 {
  font-size: 192px !important;
}

.font__size--193 {
  font-size: 193px !important;
}

.font__size--194 {
  font-size: 194px !important;
}

.font__size--195 {
  font-size: 195px !important;
}

.font__size--196 {
  font-size: 196px !important;
}

.font__size--197 {
  font-size: 197px !important;
}

.font__size--198 {
  font-size: 198px !important;
}

.font__size--199 {
  font-size: 199px !important;
}

.font__size--200 {
  font-size: 200px !important;
}

.inter {
  font-family: "Inter", sans-serif;
}

h1 {
  font-size: 70px;
}

h2 {
  font-size: 50px;
}
@media screen and (max-width: 850px) {
  h2 {
    font-size: 35px;
  }
}

h3 {
  font-size: 40px;
}
@media screen and (max-width: 850px) {
  h3 {
    font-size: 25px;
  }
}

h4 {
  font-size: 30px;
}
@media screen and (max-width: 850px) {
  h4 {
    font-size: 20px;
  }
}

h5 {
  font-size: 24px;
}
@media screen and (max-width: 850px) {
  h5 {
    font-size: 18px;
  }
}

h6 {
  font-size: 18px;
}
@media screen and (max-width: 850px) {
  h6 {
    font-size: 14px;
  }
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 900;
}

.thin {
  font-weight: 100 !important;
}

.extra-light {
  font-weight: 200 !important;
}

.light {
  font-weight: 300 !important;
}

.regular {
  font-weight: 400 !important;
}

.medium {
  font-weight: 500 !important;
}

.semi-bold {
  font-weight: 600 !important;
}

.bold {
  font-weight: 700 !important;
}

.extra-bold {
  font-weight: 800 !important;
}

.black {
  font-weight: 900 !important;
}

.normal {
  font-weight: normal !important;
}

.uppercase__font {
  text-transform: uppercase;
}

.capitalize__font {
  text-transform: capitalize;
}

.justify {
  text-align: justify;
}

.underline {
  text-decoration: underline;
}

@font-face {
  font-family: circular;
  src: url("../fonts/CircularStd-Book.ttf");
}
.circular {
  font-family: circular;
}

.btn {
  font-weight: 700;
  padding: 17px 45px;
  border-radius: 5px;
}
.btn:focus {
  box-shadow: none;
}
.btn__white {
  background-color: #ffffff;
}
.btn__white.shadow {
  box-shadow: none !important;
}
.btn__white.shadow:hover {
  box-shadow: 0 15px 30px rgba(255, 255, 255, 0.5) !important;
}
.btn__white.rounded-20 {
  border-radius: 20px;
}
.btn__white.opacity-3 {
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.0392156863);
}
.btn__white.pd-logout {
  padding: 17px 30px;
}
.btn__outlined--white {
  border: 2px solid #ffffff;
  color: #ffffff;
  opacity: 0.3;
  background-color: transparent;
  transition: 0.5s;
}
.btn__outlined--white.b-1 {
  border: 1px solid #ffffff;
}
.btn__outlined--white.h__black:hover {
  background-color: #082A3A !important;
}
.btn__outlined--white.no__opacity {
  opacity: 1 !important;
}
.btn__outlined--white.shadow {
  box-shadow: none !important;
}
.btn__outlined--white.shadow:hover {
  box-shadow: 0 15px 30px rgba(255, 255, 255, 0.5);
}
.btn__outlined--white:hover {
  opacity: 1;
  background-color: #ffffff;
  color: white !important;
}
.btn__outlined--white:hover.darkblue {
  color: !important;
}
.btn__outlined--white:hover.pink {
  color: !important;
}
.btn__black {
  color: #ffffff;
  background-color: #082A3A;
}
.btn__black.shadow {
  box-shadow: none !important;
}
.btn__black.shadow:hover {
  box-shadow: 0 15px 30px rgba(8, 42, 58, 0.5) !important;
}
.btn__black.rounded-20 {
  border-radius: 20px;
}
.btn__black.opacity-3 {
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.0392156863);
}
.btn__black.pd-logout {
  padding: 17px 30px;
}
.btn__outlined--black {
  border: 2px solid #082A3A;
  color: #082A3A;
  opacity: 0.3;
  background-color: transparent;
  transition: 0.5s;
}
.btn__outlined--black.b-1 {
  border: 1px solid #082A3A;
}
.btn__outlined--black.h__black:hover {
  background-color: #082A3A !important;
}
.btn__outlined--black.no__opacity {
  opacity: 1 !important;
}
.btn__outlined--black.shadow {
  box-shadow: none !important;
}
.btn__outlined--black.shadow:hover {
  box-shadow: 0 15px 30px rgba(8, 42, 58, 0.5);
}
.btn__outlined--black:hover {
  opacity: 1;
  background-color: #082A3A;
  color: white !important;
}
.btn__outlined--black:hover.darkblue {
  color: !important;
}
.btn__outlined--black:hover.pink {
  color: !important;
}
.btn__black-2 {
  color: #ffffff;
  background-color: #1A1A1A;
}
.btn__black-2.shadow {
  box-shadow: none !important;
}
.btn__black-2.shadow:hover {
  box-shadow: 0 15px 30px rgba(26, 26, 26, 0.5) !important;
}
.btn__black-2.rounded-20 {
  border-radius: 20px;
}
.btn__black-2.opacity-3 {
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.0392156863);
}
.btn__black-2.pd-logout {
  padding: 17px 30px;
}
.btn__outlined--black-2 {
  border: 2px solid #1A1A1A;
  color: #1A1A1A;
  opacity: 0.3;
  background-color: transparent;
  transition: 0.5s;
}
.btn__outlined--black-2.b-1 {
  border: 1px solid #1A1A1A;
}
.btn__outlined--black-2.h__black:hover {
  background-color: #082A3A !important;
}
.btn__outlined--black-2.no__opacity {
  opacity: 1 !important;
}
.btn__outlined--black-2.shadow {
  box-shadow: none !important;
}
.btn__outlined--black-2.shadow:hover {
  box-shadow: 0 15px 30px rgba(26, 26, 26, 0.5);
}
.btn__outlined--black-2:hover {
  opacity: 1;
  background-color: #1A1A1A;
  color: white !important;
}
.btn__outlined--black-2:hover.darkblue {
  color: !important;
}
.btn__outlined--black-2:hover.pink {
  color: !important;
}
.btn__black-3 {
  color: #ffffff;
  background-color: #000F11;
}
.btn__black-3.shadow {
  box-shadow: none !important;
}
.btn__black-3.shadow:hover {
  box-shadow: 0 15px 30px rgba(0, 15, 17, 0.5) !important;
}
.btn__black-3.rounded-20 {
  border-radius: 20px;
}
.btn__black-3.opacity-3 {
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.0392156863);
}
.btn__black-3.pd-logout {
  padding: 17px 30px;
}
.btn__outlined--black-3 {
  border: 2px solid #000F11;
  color: #000F11;
  opacity: 0.3;
  background-color: transparent;
  transition: 0.5s;
}
.btn__outlined--black-3.b-1 {
  border: 1px solid #000F11;
}
.btn__outlined--black-3.h__black:hover {
  background-color: #082A3A !important;
}
.btn__outlined--black-3.no__opacity {
  opacity: 1 !important;
}
.btn__outlined--black-3.shadow {
  box-shadow: none !important;
}
.btn__outlined--black-3.shadow:hover {
  box-shadow: 0 15px 30px rgba(0, 15, 17, 0.5);
}
.btn__outlined--black-3:hover {
  opacity: 1;
  background-color: #000F11;
  color: white !important;
}
.btn__outlined--black-3:hover.darkblue {
  color: !important;
}
.btn__outlined--black-3:hover.pink {
  color: !important;
}
.btn__tosca {
  color: #ffffff;
  background-color: #00E6F6;
}
.btn__tosca.shadow {
  box-shadow: none !important;
}
.btn__tosca.shadow:hover {
  box-shadow: 0 15px 30px rgba(0, 230, 246, 0.5) !important;
}
.btn__tosca.rounded-20 {
  border-radius: 20px;
}
.btn__tosca.opacity-3 {
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.0392156863);
}
.btn__tosca.pd-logout {
  padding: 17px 30px;
}
.btn__outlined--tosca {
  border: 2px solid #00E6F6;
  color: #00E6F6;
  opacity: 0.3;
  background-color: transparent;
  transition: 0.5s;
}
.btn__outlined--tosca.b-1 {
  border: 1px solid #00E6F6;
}
.btn__outlined--tosca.h__black:hover {
  background-color: #082A3A !important;
}
.btn__outlined--tosca.no__opacity {
  opacity: 1 !important;
}
.btn__outlined--tosca.shadow {
  box-shadow: none !important;
}
.btn__outlined--tosca.shadow:hover {
  box-shadow: 0 15px 30px rgba(0, 230, 246, 0.5);
}
.btn__outlined--tosca:hover {
  opacity: 1;
  background-color: #00E6F6;
  color: white !important;
}
.btn__outlined--tosca:hover.darkblue {
  color: !important;
}
.btn__outlined--tosca:hover.pink {
  color: !important;
}
.btn__red {
  color: #ffffff;
  background-color: #E93527;
}
.btn__red.shadow {
  box-shadow: none !important;
}
.btn__red.shadow:hover {
  box-shadow: 0 15px 30px rgba(233, 53, 39, 0.5) !important;
}
.btn__red.rounded-20 {
  border-radius: 20px;
}
.btn__red.opacity-3 {
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.0392156863);
}
.btn__red.pd-logout {
  padding: 17px 30px;
}
.btn__outlined--red {
  border: 2px solid #E93527;
  color: #E93527;
  opacity: 0.3;
  background-color: transparent;
  transition: 0.5s;
}
.btn__outlined--red.b-1 {
  border: 1px solid #E93527;
}
.btn__outlined--red.h__black:hover {
  background-color: #082A3A !important;
}
.btn__outlined--red.no__opacity {
  opacity: 1 !important;
}
.btn__outlined--red.shadow {
  box-shadow: none !important;
}
.btn__outlined--red.shadow:hover {
  box-shadow: 0 15px 30px rgba(233, 53, 39, 0.5);
}
.btn__outlined--red:hover {
  opacity: 1;
  background-color: #E93527;
  color: white !important;
}
.btn__outlined--red:hover.darkblue {
  color: !important;
}
.btn__outlined--red:hover.pink {
  color: !important;
}
.btn__transparent {
  color: #ffffff;
  background-color: transparent;
}
.btn__transparent.shadow {
  box-shadow: none !important;
}
.btn__transparent.shadow:hover {
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0) !important;
}
.btn__transparent.rounded-20 {
  border-radius: 20px;
}
.btn__transparent.opacity-3 {
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.0392156863);
}
.btn__transparent.pd-logout {
  padding: 17px 30px;
}
.btn__outlined--transparent {
  border: 2px solid transparent;
  color: transparent;
  opacity: 0.3;
  background-color: transparent;
  transition: 0.5s;
}
.btn__outlined--transparent.b-1 {
  border: 1px solid transparent;
}
.btn__outlined--transparent.h__black:hover {
  background-color: #082A3A !important;
}
.btn__outlined--transparent.no__opacity {
  opacity: 1 !important;
}
.btn__outlined--transparent.shadow {
  box-shadow: none !important;
}
.btn__outlined--transparent.shadow:hover {
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0);
}
.btn__outlined--transparent:hover {
  opacity: 1;
  background-color: transparent;
  color: white !important;
}
.btn__outlined--transparent:hover.darkblue {
  color: !important;
}
.btn__outlined--transparent:hover.pink {
  color: !important;
}
.btn__gray-1 {
  color: #ffffff;
  background-color: #959595;
}
.btn__gray-1.shadow {
  box-shadow: none !important;
}
.btn__gray-1.shadow:hover {
  box-shadow: 0 15px 30px rgba(149, 149, 149, 0.5) !important;
}
.btn__gray-1.rounded-20 {
  border-radius: 20px;
}
.btn__gray-1.opacity-3 {
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.0392156863);
}
.btn__gray-1.pd-logout {
  padding: 17px 30px;
}
.btn__outlined--gray-1 {
  border: 2px solid #959595;
  color: #959595;
  opacity: 0.3;
  background-color: transparent;
  transition: 0.5s;
}
.btn__outlined--gray-1.b-1 {
  border: 1px solid #959595;
}
.btn__outlined--gray-1.h__black:hover {
  background-color: #082A3A !important;
}
.btn__outlined--gray-1.no__opacity {
  opacity: 1 !important;
}
.btn__outlined--gray-1.shadow {
  box-shadow: none !important;
}
.btn__outlined--gray-1.shadow:hover {
  box-shadow: 0 15px 30px rgba(149, 149, 149, 0.5);
}
.btn__outlined--gray-1:hover {
  opacity: 1;
  background-color: #959595;
  color: white !important;
}
.btn__outlined--gray-1:hover.darkblue {
  color: !important;
}
.btn__outlined--gray-1:hover.pink {
  color: !important;
}
.btn__gray-2 {
  background-color: #BCC6C8;
}
.btn__gray-2.shadow {
  box-shadow: none !important;
}
.btn__gray-2.shadow:hover {
  box-shadow: 0 15px 30px rgba(188, 198, 200, 0.5) !important;
}
.btn__gray-2.rounded-20 {
  border-radius: 20px;
}
.btn__gray-2.opacity-3 {
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.0392156863);
}
.btn__gray-2.pd-logout {
  padding: 17px 30px;
}
.btn__outlined--gray-2 {
  border: 2px solid #BCC6C8;
  color: #BCC6C8;
  opacity: 0.3;
  background-color: transparent;
  transition: 0.5s;
}
.btn__outlined--gray-2.b-1 {
  border: 1px solid #BCC6C8;
}
.btn__outlined--gray-2.h__black:hover {
  background-color: #082A3A !important;
}
.btn__outlined--gray-2.no__opacity {
  opacity: 1 !important;
}
.btn__outlined--gray-2.shadow {
  box-shadow: none !important;
}
.btn__outlined--gray-2.shadow:hover {
  box-shadow: 0 15px 30px rgba(188, 198, 200, 0.5);
}
.btn__outlined--gray-2:hover {
  opacity: 1;
  background-color: #BCC6C8;
  color: white !important;
}
.btn__outlined--gray-2:hover.darkblue {
  color: !important;
}
.btn__outlined--gray-2:hover.pink {
  color: !important;
}
.btn__gray-3 {
  color: #ffffff;
  background-color: #464F51;
}
.btn__gray-3.shadow {
  box-shadow: none !important;
}
.btn__gray-3.shadow:hover {
  box-shadow: 0 15px 30px rgba(70, 79, 81, 0.5) !important;
}
.btn__gray-3.rounded-20 {
  border-radius: 20px;
}
.btn__gray-3.opacity-3 {
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.0392156863);
}
.btn__gray-3.pd-logout {
  padding: 17px 30px;
}
.btn__outlined--gray-3 {
  border: 2px solid #464F51;
  color: #464F51;
  opacity: 0.3;
  background-color: transparent;
  transition: 0.5s;
}
.btn__outlined--gray-3.b-1 {
  border: 1px solid #464F51;
}
.btn__outlined--gray-3.h__black:hover {
  background-color: #082A3A !important;
}
.btn__outlined--gray-3.no__opacity {
  opacity: 1 !important;
}
.btn__outlined--gray-3.shadow {
  box-shadow: none !important;
}
.btn__outlined--gray-3.shadow:hover {
  box-shadow: 0 15px 30px rgba(70, 79, 81, 0.5);
}
.btn__outlined--gray-3:hover {
  opacity: 1;
  background-color: #464F51;
  color: white !important;
}
.btn__outlined--gray-3:hover.darkblue {
  color: !important;
}
.btn__outlined--gray-3:hover.pink {
  color: !important;
}

#a {
  padding-left: 0px;
  margin: 0;
}

#a img,
.btn-select img {
  width: 12px;
}

#a li {
  list-style: none;
  padding: 5px;
}

#a li:hover {
  background-color: #f4f3f3;
}

#a li img {
  margin: 5px;
}

#a li span,
.btn-select li span {
  margin-left: 10px;
}

/* item list */
.b {
  display: none;
  max-width: 250px;
  width: 100%;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  position: absolute;
  z-index: 2;
  background-color: white;
}

.open {
  display: show !important;
}

.btn-select {
  margin-top: 0;
  height: 30px;
  max-width: 250px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #cfd0d7;
  background-color: #fcfcfd;
  position: relative;
}

.btn-select li {
  list-style: none;
  float: left;
  padding-bottom: 0px;
  padding: 0 5px;
}

.btn-select:hover li {
  margin-left: 0px;
}

.btn-select:focus {
  outline: none;
}

.lang-select {
  margin-left: 15px;
}

@keyframes zoom {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3, 1.3);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes floating {
  0% {
    transform: translateX(-50%) translateY(0%);
  }
  50% {
    transform: translateX(-50%) translateY(8%);
  }
  100% {
    transform: translateX(-50%) translateY(0%);
  }
}
@keyframes floatingY {
  0% {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(8%);
  }
  100% {
    transform: translateY(0%);
  }
}
@keyframes cliprotate {
  0% {
    transform: rotate(0deg);
    border-top-color: white;
  }
  25% {
    transform: rotate(90deg);
    border-top-color: white;
    border-right-color: white;
  }
  75% {
    transform: rotate(180deg);
    border-top-color: white;
    border-right-color: white;
    border-bottom-color: white;
  }
  100% {
    transform: rotate(360deg);
    border-top-color: white;
    border-right-color: white;
    border-bottom-color: white;
    border-left-color: white;
  }
}
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes orbit {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(-360deg);
  }
}
@keyframes orbit-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
@keyframes orbit-reverse {
  0% {
    transform: rotateZ(-3600deg);
  }
  100% {
    transform: rotateZ(0deg);
  }
}
@keyframes bgScrollLeft {
  from {
    background-position: 100% 0;
  }
  to {
    background-position: 100% -230px;
  }
}
@keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 #ffffff;
    box-shadow: 0 0 0 0 #ffffff;
  }
  70% {
    -webkit-box-shadow: 0 0 0 40px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 40px rgba(255, 255, 255, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}
@keyframes action-bounce {
  0%, 100% {
    transform: translate(0, 0);
  }
  10% {
    transform: translate(0, 4px);
  }
  50% {
    transform: translate(0, -15px);
  }
  90% {
    transform: translate(0, 4px);
  }
}
@keyframes action-jelly {
  0%, 100% {
    transform: scaleX(1);
  }
  20% {
    transform: scaleX(0.9);
  }
  50% {
    transform: scaleX(1.25);
  }
  85% {
    transform: scaleX(0.8);
  }
}
@keyframes jello {
  11.1% {
    transform: none;
  }
  22.2% {
    transform: skewX(-12.5deg) skewY(-12.5deg);
  }
  33.3% {
    transform: skewX(6.25deg) skewY(6.25deg);
  }
  44.4% {
    transform: skewX(-3.125deg) skewY(-3.125deg);
  }
  55.5% {
    transform: skewX(1.5625deg) skewY(1.5625deg);
  }
  66.6% {
    transform: skewX(-0.78125deg) skewY(-0.78125deg);
  }
  77.7% {
    transform: skewX(0.390625deg) skewY(0.390625deg);
  }
  88.8% {
    transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
  }
  100% {
    transform: none;
  }
}
@keyframes jumping {
  0% {
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px);
  }
  70% {
    -webkit-transform: scale(1.02, 0.9) translateY(10px);
    transform: scale(1.02, 0.9) translateY(10px);
  }
  100% {
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px);
  }
}
@keyframes jumping-rotate {
  0% {
    -webkit-transform: translateY(-40px) rotate(45deg);
    transform: translateY(-40px) rotate(45deg);
  }
  70% {
    -webkit-transform: scale(1.02, 0.9) translateY(10px) rotate(45deg);
    transform: scale(1.02, 0.9) translateY(10px) rotate(45deg);
  }
  100% {
    -webkit-transform: translateY(-40px) rotate(45deg);
    transform: translateY(-40px) rotate(45deg);
  }
}
@keyframes boxWithShadow {
  0% {
    width: 80%;
    opacity: 1;
  }
  50% {
    width: 50%;
    opacity: 0.5;
  }
  to {
    width: 80%;
    opacity: 1;
  }
}
@keyframes bounceDown-on {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-8px);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes bounceRight-on {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-8px);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes bounceDown {
  0% {
    transform: translateY(0) scale(0.88);
  }
  50% {
    transform: translateY(-5rem) scale(0.88);
  }
  to {
    transform: translateY(0) scale(0.88);
  }
}
@keyframes shadowRegulator {
  0% {
    width: 80%;
    opacity: 1;
  }
  50% {
    width: 50%;
    opacity: 0.5;
  }
  to {
    width: 80%;
    opacity: 1;
  }
}
@keyframes upDown {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-1rem);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes upDown2 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-0.5rem);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes upDown-totate {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-1rem);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes scale1 {
  0% {
    transform: scale(1) rotate(0deg);
  }
  50% {
    transform: scale(1.1) rotate(360deg);
  }
  100% {
    transform: scale(1) rotate(0deg);
  }
}
@keyframes roate1 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes roate1rivers {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}
@keyframes scaleImage {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes backForward {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(25%);
  }
  100% {
    transform: translateX(0%);
  }
}
@keyframes leftTop {
  from {
    opacity: 0;
    transform: rotate(360deg);
  }
  to {
    opacity: 1;
    transform: rotate(0deg);
  }
}
@keyframes leftBottom {
  from {
    opacity: 0;
    transform: rotate(-360deg);
  }
  to {
    opacity: 1;
    transform: rotate(0deg);
  }
}
@keyframes rightTop {
  from {
    opacity: 0;
    transform: rotate(-100deg);
  }
  to {
    opacity: 1;
    transform: rotate(0deg);
  }
}
@keyframes rightBottom {
  from {
    opacity: 0;
    transform: rotate(100deg);
  }
  to {
    opacity: 1;
    transform: rotate(0deg);
  }
}
@keyframes blurAnimate {
  0% {
    filter: blur(10px);
  }
  50% {
    filter: blur(5px);
  }
  100% {
    filter: blur(10px);
  }
}
@keyframes rightLeft {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(45deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
:root {
  --height: 100vh;
}

.animate__float1 {
  animation: floatingY 4s ease-in-out infinite;
}
.animate__float2 {
  animation: floatingY 6s ease-in-out infinite;
}
.animate__rotate1 {
  animation: orbit-rotate 6s linear infinite;
  transform-origin: center center;
}
.animate__rotate-reverse-1 {
  animation: orbit-reverse 10s linear infinite;
}
.animate__upDown {
  animation: upDown2 1s linear infinite;
}
.animate__jump {
  animation: bounceDown-on 1s linear infinite;
}
.animate__jump-1 {
  animation: bounceDown 1.5s linear infinite;
}
.animate__jump-2 {
  animation: bounceDown 1.8s linear infinite 1s;
}
.animate__jump-3 {
  animation: bounceDown 1.5s linear infinite 1.5s;
}
.animate__jump-4 {
  animation: bounceDown 1.8s linear infinite 2s;
}
.animate__scale1 {
  animation: scale1 1s ease infinite;
  transform-origin: center;
  transform-box: fill-box;
}
.animate__scale2 {
  animation: scale1 2s ease infinite;
  transform-origin: center;
  transform-box: fill-box;
}
.animate__scale3 {
  animation: scale1 3s ease infinite;
  transform-origin: center;
  transform-box: fill-box;
}
.animate__scale4 {
  animation: scale1 4s ease infinite;
  transform-origin: center;
  transform-box: fill-box;
}
.animate__pulse {
  border-radius: 50%;
  animation: pulse 2s infinite;
}

input[type=number] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.wrapper__chat-wrap-list {
  height: calc(100% - (125px + 1.5rem));
  overflow: auto;
}
.wrapper__category-slide {
  display: flex;
  align-items: center;
  overflow: auto;
  margin: 0 -5px;
}
.wrapper__category-slide::-webkit-scrollbar {
  display: none;
}
.wrapper__category-slide .items {
  margin: 0 5px;
  flex-shrink: 0;
  padding: 0.5rem 1rem;
  background: #082a3a;
  border: 1px solid #bcc6c8;
  border-radius: 16px;
  width: max-content;
  text-align: center;
}
.wrapper__category-slide .items.active {
  background: rgba(0, 230, 246, 0.12);
  border: 1px solid rgba(0, 230, 246, 0.24);
  filter: drop-shadow(0px 8px 24px #04131b);
}
.wrapper__box-chat {
  height: calc(100% - (253px + 1.5rem));
  overflow: auto;
}
.wrapper__type-chat {
  padding: 1rem 0;
  padding-bottom: 2rem;
  background: #002429;
  border-radius: 20px 20px 0px 0px;
}
.wrapper__type-chat .field .send {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(8, 42, 58, 0.48);
  border: 1px solid rgba(0, 230, 246, 0.16);
  backdrop-filter: blur(10px);
}
.wrapper__type-chat .field input {
  height: 48px;
  width: 100%;
  box-shadow: none;
  outline-style: none;
  padding: 0 1rem;
  background: rgba(8, 42, 58, 0.48);
  border: 1px solid rgba(0, 230, 246, 0.16);
  border-radius: 24px;
}
.wrapper__type-chat .field input:hover {
  box-shadow: none;
  outline-style: none;
  padding: 0 1rem;
  background: rgba(8, 42, 58, 0.48);
  border: 1px solid rgba(0, 230, 246, 0.16);
  border-radius: 24px;
}
.wrapper__type-chat .field input:focus {
  box-shadow: none;
  outline-style: none;
  padding: 0 1rem;
  background: rgba(8, 42, 58, 0.48);
  border: 1px solid rgba(0, 230, 246, 0.16);
  border-radius: 24px;
}
.wrapper__type-chat .field input:active {
  box-shadow: none;
  outline-style: none;
  padding: 0 1rem;
  background: rgba(8, 42, 58, 0.48);
  border: 1px solid rgba(0, 230, 246, 0.16);
  border-radius: 24px;
}
.wrapper__admin-text-chat {
  max-width: 75vw;
  margin-right: auto;
}
.wrapper__admin-text-chat .wrap .user {
  display: flex;
  align-items: flex-end;
}
.wrapper__admin-text-chat .wrap .user > img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}
.wrapper__admin-text-chat .wrap .user .text__desc {
  padding: 1rem;
  background: #082A3A;
  border-radius: 16px 16px 16px 0px;
}
.wrapper__user-text-chat {
  max-width: 90vw;
  margin-left: auto;
}
.wrapper__user-text-chat .text__desc {
  padding: 1rem;
  background-color: white;
  border-radius: 16px 16px 0px 16px;
}
.wrapper__chatlist-detail {
  position: fixed;
  width: 100%;
  height: 100vh;
  overflow: auto;
  left: 0;
  top: 150%;
  z-index: 14;
  transition: 0.5s;
}
@media screen and (max-width: 576px) {
  .wrapper__chatlist-detail {
    height: var(--height);
  }
}
.wrapper__chatlist-detail.active {
  top: 0;
}
.wrapper__chatlist-detail .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0;
}
.wrapper__chatlist-detail .title__chat {
  border: none;
  border-top: 1px solid rgba(255, 255, 255, 0.1215686275);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1215686275);
  padding: 1rem 0;
}
.wrapper__chatlist-detail .title__chat .user {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.wrapper__chatlist-detail .title__chat .user .profile {
  display: flex;
  align-items: center;
}
.wrapper__chatlist-detail .title__chat .user .profile > img {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  object-fit: cover;
}
.wrapper__chatList.active {
  background: rgba(0, 230, 246, 0.0784313725);
}
.wrapper__chatList .container .detail {
  padding: 1rem 0;
  display: flex;
  align-items: flex-start;
}
.wrapper__chatList .container .detail .img__user {
  position: relative;
}
.wrapper__chatList .container .detail .img__user img {
  width: 48px;
  height: 48px;
  object-fit: cover;
  border-radius: 50%;
}
.wrapper__chatList .container .detail .img__user .count {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #E93527;
  color: white;
  position: absolute;
  right: -1px;
  bottom: -1px;
}
.wrapper__modal-chat-request {
  position: fixed;
  width: 100%;
  height: 100vh;
  overflow: auto;
  left: 0;
  top: 150%;
  z-index: 13;
  transition: 0.5s;
}
@media screen and (max-width: 576px) {
  .wrapper__modal-chat-request {
    height: var(--height);
  }
}
.wrapper__modal-chat-request.active {
  top: 0;
}
.wrapper__modal-chat-request .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0;
}
.wrapper__card-review {
  margin-bottom: 1rem;
}
.wrapper__card-review .head {
  border-radius: 20px;
  border: 1px solid #204650;
  padding: 0.5rem !important;
}
.wrapper__card-review .head .top {
  display: flex;
  align-items: center;
}
.wrapper__card-review .head hr {
  border-top: 1px solid rgba(255, 255, 255, 0.0784313725);
}
.wrapper__card-review .head .content {
  display: flex;
  align-items: flex-center;
  justify-content: flex-end;
}
.wrapper__card-review .head .content .star {
  display: flex;
  align-items: center;
  margin: 0 -2px;
}
.wrapper__card-review .head .content .star svg {
  margin: 0 2px;
}
.wrapper__card-review .head .content .desc {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}
.wrapper__card-review .head .content .desc div .tag {
  padding: 6px 13px;
  border-radius: 30px;
  line-height: initial;
  background: rgba(0, 230, 246, 0.1215686275);
  display: inline-block;
}
.wrapper__card-review .head .content .desc .cover {
  width: 70px;
  height: 70px;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
}
.wrapper__card-review .head .content .desc .cover::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(12, 12, 12, 0) 0%, rgba(12, 12, 12, 0.16) 100%), radial-gradient(50% 50% at 50% 50%, rgba(12, 12, 12, 0.32) 0%, rgba(12, 12, 12, 0.32) 100%);
  left: 0;
  top: 0;
}
.wrapper__card-review .head .content .desc .cover img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.wrapper__filed-general {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  background: rgba(8, 42, 58, 0.48);
  border: 1px solid rgba(0, 230, 246, 0.16);
  border-radius: 50px;
}
.wrapper__filed-general > div {
  width: 100%;
}
.wrapper__filed-general > div div [class*=singleValue] {
  color: white;
}
.wrapper__filed-general > div > div,
.wrapper__filed-general > div > div:focus {
  background: transparent;
  border: none;
  text-align: left;
  box-shadow: none !important;
  color: white;
  padding: 0px !important;
  cursor: text;
}
.wrapper__filed-general > div > div input,
.wrapper__filed-general > div > div:focus input {
  color: white !important;
}
.wrapper__filed-general > div div[class*=IndicatorsContainer] {
  display: none;
}
.wrapper__filed-general > div #react-select-2-listbox {
  background-color: white !important;
  color: #04131b;
}
.wrapper__filed-general input {
  box-shadow: none;
  border-radius: 0;
  outline-style: none;
  background-color: transparent;
  border: none;
  padding: 0;
  color: #fff;
}
.wrapper__filed-general input:hover {
  box-shadow: none;
  border-radius: 0;
  outline-style: none;
  background-color: transparent;
  border: none;
  padding: 0;
}
.wrapper__filed-general input:focus {
  box-shadow: none;
  border-radius: 0;
  outline-style: none;
  background-color: transparent;
  border: none;
  padding: 0;
}
.wrapper__filed-general input:active {
  box-shadow: none;
  border-radius: 0;
  outline-style: none;
  background-color: transparent;
  border: none;
  padding: 0;
}
.wrapper__wrap-up {
  position: fixed;
  z-index: 11;
  width: 100%;
  left: 0;
  top: 100%;
  height: 100%;
  background: #1a1a1a;
  opacity: 0.8;
  transition: 0.5s;
}
.wrapper__wrap-up.updated {
  z-index: 15;
}
.wrapper__wrap-up.active {
  top: 0;
}
.wrapper__up-request {
  position: fixed;
  z-index: 12;
  width: 100%;
  left: 0;
  bottom: -150%;
  background-color: #082A3A;
  border-radius: 20px 20px 0 0;
  transition: 0.5s;
}
.wrapper__up-request.updated {
  z-index: 16;
}
.wrapper__up-request.active {
  bottom: 0;
}
.wrapper__up-request .head {
  padding: 1.5rem 0;
  padding-bottom: 2rem;
}
.wrapper__up-request .head .ornamen {
  width: 34px;
  height: 4px;
  border-radius: 50px;
  background-color: #464f51;
  margin: 0 auto;
}
.wrapper__up-request .body {
  padding: 1.5rem 0;
  padding-top: 0;
}
.wrapper__up-request .body .general .arrow {
  width: 57px;
  height: 57px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.wrapper__up-request .body .review textarea {
  padding: 0.5rem;
  border: 1px solid #204650;
  border-radius: 16px;
  background-color: transparent;
  outline-style: none;
  box-shadow: none;
  width: 100%;
}
.wrapper__up-request .body .review .reveiw__list > div {
  margin: 0 -0.5rem;
}
.wrapper__up-request .body .review .reveiw__list > div .items {
  margin: 0 0.5rem;
  margin-bottom: 1rem;
}
.wrapper__up-request .body .review .reveiw__list > div .tag {
  padding: 8px 15px;
  border-radius: 6px;
  border: 1px solid #204650;
}
.wrapper__up-request .body .size .list__size {
  display: flex;
  align-items: center;
  overflow: auto;
  margin: 0 -5px;
}
.wrapper__up-request .body .size .list__size::-webkit-scrollbar {
  display: none;
}
.wrapper__up-request .body .size .list__size .items {
  margin: 0 5px;
  flex-shrink: 0;
  background: #082a3a;
  border: 1px solid #bcc6c8;
  border-radius: 50%;
  width: max-content;
  text-align: center;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.wrapper__up-request .body .size .list__size .items.active {
  background: rgba(0, 230, 246, 0.12);
  border: 1px solid rgba(0, 230, 246, 0.24);
  filter: drop-shadow(0px 8px 24px #04131b);
}
.wrapper__up-request .body .time .list__time {
  display: flex;
  align-items: center;
  overflow: auto;
  margin: 0 -5px;
}
.wrapper__up-request .body .time .list__time::-webkit-scrollbar {
  display: none;
}
.wrapper__up-request .body .time .list__time .items {
  margin: 0 5px;
  flex-shrink: 0;
  padding: 1rem;
  background: #082a3a;
  border: 1px solid #bcc6c8;
  border-radius: 16px;
  width: max-content;
  text-align: center;
}
.wrapper__up-request .body .time .list__time .items.active {
  background: rgba(0, 230, 246, 0.12);
  border: 1px solid rgba(0, 230, 246, 0.24);
  filter: drop-shadow(0px 8px 24px #04131b);
}
.wrapper__up-request .body .date .dateFormat .react-datepicker__header {
  background-color: transparent;
  border: none;
}
.wrapper__up-request .body .date .dateFormat .react-datepicker__header .react-datepicker__day-names .react-datepicker__day-name {
  color: #BCC6C8;
}
.wrapper__up-request .body .date .dateFormat .react-datepicker__navigation {
  top: 10px;
}
.wrapper__up-request .body .date .dateFormat .react-datepicker {
  width: 100%;
  font-size: 14px;
  background-color: transparent;
  border: none;
}
.wrapper__up-request .body .date .dateFormat .react-datepicker__month-container {
  width: 100%;
}
.wrapper__up-request .body .date .dateFormat .react-datepicker__current-month {
  color: #BCC6C8;
  padding-bottom: 1rem;
}
.wrapper__up-request .body .date .dateFormat .react-datepicker__day-name,
.wrapper__up-request .body .date .dateFormat .react-datepicker__day,
.wrapper__up-request .body .date .dateFormat .react-datepicker__time-name {
  width: 2.5rem;
  line-height: 2.5rem;
}
.wrapper__up-request .body .date .dateFormat .react-datepicker__day {
  color: white;
}
.wrapper__up-request .body .date .dateFormat .react-datepicker__day--selected {
  background: rgba(0, 230, 246, 0.12);
  border: 1px solid rgba(0, 230, 246, 0.24);
  box-shadow: 0px 8px 24px #04131b;
  border-radius: 12px;
}
.wrapper__up-request .body .date .dateFormat .react-datepicker__day--keyboard-selected {
  background-color: transparent;
}
.wrapper__up-request .body .date .dateFormat .react-datepicker__week .react-datepicker__day--disabled {
  color: white;
  opacity: 0.5;
}
.wrapper__up-request .body .cuisine .list__wrap {
  max-height: 60vh;
  overflow: auto;
}
.wrapper__up-request .body .cuisine .list__wrap.updated {
  max-height: 48vh;
}
.wrapper__up-request .body .cuisine .list__wrap .items {
  width: 100%;
  padding: 1rem;
  background: #082a3a;
  border: 1px solid #204650;
  border-radius: 16px;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  transition: 0.5s;
}
.wrapper__up-request .body .cuisine .list__wrap .items:last-child {
  margin-bottom: 0;
}
.wrapper__up-request .body .cuisine .list__wrap .items .line {
  width: 1px;
  height: 30px;
  background-color: #204650;
  margin: 0 10px;
  display: inline-block;
}
.wrapper__up-request .body .cuisine .list__wrap .items.active {
  border: 1px solid #00E6F6;
  box-shadow: 0px 8px 24px #04131b;
}
.wrapper__up-request .body .cuisine .list__wrap .items.active .line {
  background-color: #00E6F6;
}
.wrapper__up-request .body .occasion .list__wrap .items {
  width: 100%;
  padding: 1rem;
  background: #082a3a;
  border: 1px solid #204650;
  border-radius: 16px;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  transition: 0.5s;
}
.wrapper__up-request .body .occasion .list__wrap .items:last-child {
  margin-bottom: 0;
}
.wrapper__up-request .body .occasion .list__wrap .items .line {
  width: 1px;
  height: 30px;
  background-color: #204650;
  margin: 0 10px;
  display: inline-block;
}
.wrapper__up-request .body .occasion .list__wrap .items.active {
  border: 1px solid #00E6F6;
  box-shadow: 0px 8px 24px #04131b;
}
.wrapper__up-request .body .occasion .list__wrap .items.active .line {
  background-color: #00E6F6;
}
.wrapper__up-request .body .upcoming .select .items {
  width: 100%;
  border: 1px solid #1d4049;
  border-radius: 16px;
  padding: 0.7rem 1rem;
}
.wrapper__up-request .body .upcoming .select .items.active {
  border: 1px solid #00e6f6;
  filter: drop-shadow(0px 8px 24px #04131b);
  color: white !important;
}
.wrapper__logo-modal {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.wrapper__logo-modal::before {
  position: absolute;
  content: "";
  width: 140px;
  height: 140px;
  border-radius: 50%;
  background: rgba(0, 230, 246, 0.2);
  filter: blur(28px);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.wrapper__field-profile {
  box-shadow: none;
  border-radius: 0;
  outline-style: none;
  background-color: transparent;
  border: none;
  padding: 0;
}
.wrapper__field-profile::-webkit-input-placeholder {
  /* Edge */
  color: white;
}
.wrapper__field-profile:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: white;
}
.wrapper__field-profile::placeholder {
  color: white;
}
.wrapper__field-profile:hover {
  box-shadow: none;
  border-radius: 0;
  outline-style: none;
  background-color: transparent;
  border: none;
  padding: 0;
}
.wrapper__field-profile:focus {
  box-shadow: none;
  border-radius: 0;
  outline-style: none;
  background-color: transparent;
  border: none;
  padding: 0;
}
.wrapper__field-profile:active {
  box-shadow: none;
  border-radius: 0;
  outline-style: none;
  background-color: transparent;
  border: none;
  padding: 0;
}
.wrapper__photo-profile {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.wrapper__photo-profile input {
  position: absolute;
  z-index: -1;
  visibility: hidden;
  opacity: 0;
}
.wrapper__photo-profile::before {
  position: absolute;
  content: "";
  width: 140px;
  height: 140px;
  border-radius: 50%;
  background: rgba(0, 230, 246, 0.2);
  filter: blur(28px);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.wrapper__photo-profile > img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}
.wrapper__photo-profile .edit {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00E6F6;
  border-radius: 50%;
  position: absolute;
  right: 5px;
  bottom: -1px;
}
.wrapper__wrap-opening {
  background: rgba(8, 42, 58, 0.48);
  border: 1px solid rgba(0, 230, 246, 0.16);
  backdrop-filter: blur(10px);
  border-radius: 24px;
  padding: 1rem;
}
.wrapper__wrap-all-screan-home {
  height: var(--height);
}
.wrapper__wrap-all-screan-home .scroller {
  height: 100%;
  overflow: auto;
}
.wrapper__top-nav-profile {
  padding: 1.5rem 0;
}
.wrapper__alertMemberDetail {
  position: absolute;
  z-index: 5;
  background-color: #082A3A;
  width: 100%;
  height: 100%;
  top: 100%;
  left: 0;
  padding: 2rem 0;
  transition: 0.5s;
}
.wrapper__alertMemberDetail.active {
  top: 0;
}
.wrapper__alertMemberDetail div div .annual__wrap {
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.0784313725);
  padding: 1.5rem 1rem;
}
.wrapper__alertMemberDetail div div .annual__wrap .limit {
  display: inline-block;
  padding: 4px 16px;
  border-radius: 40px;
  background: rgba(233, 53, 39, 0.3215686275);
}
.wrapper__modal-wrap {
  display: block;
  visibility: hidden;
  transition: 0.5s;
  background-color: transparent !important;
}
.wrapper__modal-wrap .bg__wrap {
  background: #1a1a1a;
  opacity: 0.8;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
}
.wrapper__modal-wrap .modal-dialog {
  z-index: 1059;
}
.wrapper__modal-wrap .modal-dialog .modal-content {
  padding: 40px 30px;
  background: #082a3a;
  box-shadow: 0px 24px 44px rgba(0, 0, 0, 0.15);
  border-radius: 24px;
}
.wrapper__modal-wrap .modal-dialog .modal-content.logout div .round {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 141px;
  height: 141px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.08);
  margin: 0 auto;
}
.wrapper__modal-wrap.show {
  visibility: visible;
}
.wrapper__alert {
  position: absolute;
  max-width: 1300px;
}
@media screen and (max-width: 1200px) {
  .wrapper__alert {
    max-width: 85%;
  }
}
.wrapper__alert.membership {
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
  bottom: -20rem;
  padding: 1rem;
  background: linear-gradient(88.17deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.08) 101.93%);
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(10px);
  border-radius: 16px;
  z-index: 3;
  transition: 0.5s;
}
.wrapper__alert.membership.active {
  bottom: 6rem;
}
.wrapper__card-reservation {
  border-radius: 14px;
  background: rgba(8, 42, 58, 0.48);
  box-shadow: 0px 24px 27px rgba(0, 0, 0, 0.08);
  overflow: hidden;
}
.wrapper__card-reservation .bottom {
  padding: 1rem;
  width: 100%;
  background: rgba(255, 255, 255, 0.02);
}
.wrapper__card-reservation .bottom .items {
  margin: 0 4px;
  padding: 6px 13px;
  border-radius: 30px;
  line-height: initial;
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.0509803922);
}
.wrapper__card-reservation .head {
  padding: 1rem;
}
.wrapper__card-reservation .head .top {
  display: flex;
  align-items: center;
}
.wrapper__card-reservation .head hr {
  border-top: 1px solid rgba(255, 255, 255, 0.0784313725);
}
.wrapper__card-reservation .head .content {
  display: flex;
  align-items: flex-center;
  justify-content: flex-end;
}
.wrapper__card-reservation .head .content .star {
  display: flex;
  align-items: center;
  margin: 0 -2px;
}
.wrapper__card-reservation .head .content .star svg {
  margin: 0 2px;
}
.wrapper__card-reservation .head .content .desc {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}
.wrapper__card-reservation .head .content .desc div .tag {
  padding: 6px 13px;
  border-radius: 30px;
  line-height: initial;
  background: rgba(0, 230, 246, 0.1215686275);
  display: inline-block;
}
.wrapper__card-reservation .head .content .desc .cover {
  width: 70px;
  height: 70px;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
}
.wrapper__card-reservation .head .content .desc .cover::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(12, 12, 12, 0) 0%, rgba(12, 12, 12, 0.16) 100%), radial-gradient(50% 50% at 50% 50%, rgba(12, 12, 12, 0.32) 0%, rgba(12, 12, 12, 0.32) 100%);
  left: 0;
  top: 0;
}
.wrapper__card-reservation .head .content .desc .cover img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.wrapper__search {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  background: rgba(8, 42, 58, 0.48);
  border: 1px solid rgba(0, 230, 246, 0.16);
  border-radius: 50px;
}
.wrapper__search input {
  box-shadow: none;
  border-radius: 0;
  outline-style: none;
  background-color: transparent;
  border: none;
  padding: 0;
}
.wrapper__search input::-webkit-input-placeholder {
  /* Edge */
  color: white;
}
.wrapper__search input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: white;
}
.wrapper__search input::placeholder {
  color: white;
}
.wrapper__search input:hover {
  box-shadow: none;
  border-radius: 0;
  outline-style: none;
  background-color: transparent;
  border: none;
  padding: 0;
}
.wrapper__search input:focus {
  box-shadow: none;
  border-radius: 0;
  outline-style: none;
  background-color: transparent;
  border: none;
  padding: 0;
}
.wrapper__search input:active {
  box-shadow: none;
  border-radius: 0;
  outline-style: none;
  background-color: transparent;
  border: none;
  padding: 0;
}
.wrapper__make-request {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 1rem 0;
  padding-top: 2rem;
  z-index: 10;
  background: linear-gradient(180deg, rgba(12, 12, 12, 0) 4.2%, #0c0c0c 56.47%);
}
.wrapper__make-request .message {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1a1a1a;
  border-radius: 50%;
}
.wrapper__make-request .message .count {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #00E6F6;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 8px;
  top: 9px;
}
.wrapper__field-input {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem;
  background: rgba(8, 42, 58, 0.48);
  border: 1px solid rgba(0, 230, 246, 0.16);
  border-radius: 16px;
}
.wrapper__field-input input {
  width: 100%;
  border: none;
  outline-style: none;
  box-shadow: none;
  background-color: transparent;
}
.wrapper__field-input input:hover {
  border: none;
  outline-style: none;
  box-shadow: none;
  background-color: transparent;
}
.wrapper__field-input input:focus {
  border: none;
  outline-style: none;
  box-shadow: none;
  background-color: transparent;
}
.wrapper__field-input input:active {
  border: none;
  outline-style: none;
  box-shadow: none;
  background-color: transparent;
}
.wrapper__blur-wrap {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
  background: radial-gradient(59.2% 59.2% at 50% 50%, #00e6f6 0%, rgba(0, 230, 246, 0) 100%);
  mix-blend-mode: color;
}
.wrapper__bg-first {
  background: linear-gradient(180deg, rgba(8, 42, 58, 0) 0%, #082a3a 100%);
}
.wrapper__loading-video {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 9999999997;
  background-color: #ffffff;
  transition: 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
}
.wrapper__loading-video.visibelity__hidden {
  opacity: 0;
  visibility: hidden;
}
.wrapper__wrap-all-screan {
  height: 100vh;
}
@media screen and (max-width: 576px) {
  .wrapper__wrap-all-screan {
    height: var(--height);
  }
}
.wrapper__verification .vi__wrapper {
  width: 100%;
}
.wrapper__verification .vi__wrapper .vi__container {
  max-width: 303px;
  height: 56px;
  gap: 16px;
}
.wrapper__verification .vi__wrapper .vi__container .character {
  background: rgba(8, 42, 58, 0.48);
  border: 1px solid rgba(0, 230, 246, 0.16);
  border-radius: 20px;
  color: white;
  line-height: 56px;
  font-size: 24px;
}
.wrapper__verification .vi__wrapper .vi__container .character--selected {
  border: 1px solid white;
}
.wrapper__form-input .number {
  display: flex;
  align-items: center;
  background: rgba(8, 42, 58, 0.48);
  border: 1px solid rgba(0, 230, 246, 0.16);
  border-radius: 16px;
  padding: 9px 12px;
}
.wrapper__form-input .number input {
  width: 100%;
  background-color: transparent;
  border: none;
  outline-style: none;
  box-shadow: none;
}
.wrapper__form-input .number input:hover {
  background-color: transparent;
  border: none;
  outline-style: none;
  box-shadow: none;
}
.wrapper__form-input .number input:active {
  background-color: transparent;
  border: none;
  outline-style: none;
  box-shadow: none;
}
.wrapper__form-input .number input:focus {
  background-color: transparent;
  border: none;
  outline-style: none;
  box-shadow: none;
}
.wrapper__form-input .number .wrap {
  display: flex;
  align-items: center;
  background-color: #082a3a;
  padding: 7px 7px;
  border-radius: 36px;
}
.wrapper__form-input .number .wrap .react-tel-input {
  display: inline-block;
  width: 29px;
}
.wrapper__form-input .number .wrap .react-tel-input input,
.wrapper__form-input .number .wrap .react-tel-input .search-emoji {
  display: none;
}
.wrapper__form-input .number .wrap .react-tel-input .search-box {
  display: block;
  width: calc(100% - 10px);
  margin: 0;
  padding: 15px 12px;
}
.wrapper__form-input .number .wrap .react-tel-input .search-box:hover, .wrapper__form-input .number .wrap .react-tel-input .search-box:focus {
  border: 1px solid #cacaca;
}
.wrapper__form-input .number .wrap .react-tel-input .flag-dropdown {
  border: none;
}
.wrapper__form-input .number .wrap .react-tel-input .flag-dropdown .country-list {
  height: 250px;
}
.wrapper__form-input .number .wrap .react-tel-input .flag-dropdown .selected-flag .flag .arrow {
  display: none;
}
.wrapper__center-text {
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
  z-index: 4;
  max-width: 1300px;
  width: 100%;
}
@media screen and (max-width: 1200px) {
  .wrapper__center-text {
    max-width: 92%;
  }
}
.wrapper__bg-wrap {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: linear-gradient(353.83deg, #0c0c0c 53.23%, rgba(12, 12, 12, 0) 89.29%);
}

.rounded__20 {
  border-radius: 20px;
}

.hr__line-modal {
  border-top: 1px solid rgba(255, 255, 255, 0.1215686275);
}
.hr__home-line {
  border-top: 1px solid rgba(255, 255, 255, 0.1607843137);
}

.images__bg-wrap {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}
.images__cover-1 {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 2;
}
.images__cover-2 {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  width: 150rem;
  z-index: 1;
  max-width: 240%;
  background-blend-mode: luminosity;
  opacity: 0.8;
  filter: blur(20px);
}

.py__80 {
  padding-top: 80px;
  padding-bottom: 80px;
}

.btn__select {
  border-radius: 20px;
  background-color: #1d4049;
  color: #BCC6C8 !important;
}
.btn__select.active {
  background-color: #00E6F6;
  color: #082A3A !important;
}

.pt__96 {
  padding-top: 96px;
}
@media screen and (max-width: 850px) {
  .pt__96 {
    padding-top: 69px;
  }
}
.pt__50 {
  padding-top: 50px;
}
@media screen and (max-width: 850px) {
  .pt__50 {
    padding-top: 90px;
  }
}

.mb__4 {
  margin-bottom: 4rem;
}

.mt__vh-30 {
  margin-top: 30vh !important;
}
@media screen and (max-width: 850px) {
  .mt__vh-30 {
    margin-top: 1rem !important;
  }
}
@media screen and (max-width: 576px) {
  .mt__sm-4 {
    margin-top: 4rem;
  }
}
.mt-min-20 {
  margin-top: -20rem;
}
@media screen and (max-width: 850px) {
  .mt-min-20.no__md {
    margin-top: initial;
  }
}
.mt-min-6 {
  margin-top: -6rem;
}
@media screen and (max-width: 1024px) {
  .mt-min-6 {
    margin-top: -5rem;
  }
}
@media screen and (max-width: 576px) {
  .mt-min-6 {
    margin-top: -4rem;
  }
}
@media screen and (max-width: 512px) {
  .mt-min-6 {
    margin-top: -3rem;
  }
}
@media screen and (max-width: 425px) {
  .mt-min-6 {
    margin-top: -2.5rem;
  }
}
.mt__5 {
  margin-top: 5rem;
}
@media screen and (max-width: 576px) {
  .mt__5.res {
    margin-top: 3rem;
  }
}
.mt__7 {
  margin-top: 7rem;
}
.mt__10 {
  margin-top: 10rem;
}
.mt__12 {
  margin-top: 12rem;
}
.mt__20 {
  margin-top: 20rem;
}
@media screen and (max-width: 576px) {
  .mt__20.sm-no {
    margin-top: 0;
  }
}
.mt__30 {
  margin-top: 30rem;
}
@media screen and (max-width: 850px) {
  .mt__30 {
    margin-top: 17rem;
  }
}
.mt-13 {
  margin-top: 13rem;
}
.mt-6 {
  margin-top: 6rem;
}
@media screen and (max-width: 576px) {
  .mt-6.mt__no-sm {
    margin-top: 0;
  }
}
.mt-10 {
  margin-top: 10rem;
}
.mt-35 {
  margin-top: 35rem;
}
@media screen and (max-width: 850px) {
  .mt-35 {
    margin-top: 24rem;
  }
}

@media screen and (max-width: 1024px) {
  .text__163-1024 {
    font-size: 100px !important;
  }
}
@media screen and (max-width: 850px) {
  .text__163-md {
    font-size: 90px !important;
  }
}
@media screen and (max-width: 576px) {
  .text__163-sm {
    font-size: 65px !important;
  }
}
@media screen and (max-width: 480px) {
  .text__163-xs {
    font-size: 50px !important;
  }
}
@media screen and (max-width: 1024px) {
  .text__100-1024 {
    font-size: 80px !important;
  }
}
@media screen and (max-width: 850px) {
  .text__100-md {
    font-size: 70px !important;
  }
}
@media screen and (max-width: 576px) {
  .text__100-sm {
    font-size: 60px !important;
  }
}
@media screen and (max-width: 512px) {
  .text__100-mm {
    font-size: 50px !important;
  }
}
@media screen and (max-width: 425px) {
  .text__100-xx {
    font-size: 45px !important;
  }
}
@media screen and (max-width: 375px) {
  .text__100-xxs {
    font-size: 40px !important;
  }
}
@media screen and (max-width: 850px) {
  .text__200-md {
    font-size: 150px !important;
  }
}
@media screen and (max-width: 576px) {
  .text__200-sm {
    font-size: 125px !important;
  }
}
@media screen and (max-width: 512px) {
  .text__200-sm {
    font-size: 100px !important;
  }
}
@media screen and (max-width: 425px) {
  .text__200-xx {
    font-size: 85px !important;
  }
}
@media screen and (max-width: 375px) {
  .text__200-xxs {
    font-size: 75px !important;
  }
}
@media screen and (max-width: 576px) {
  .text__77-sm {
    font-size: 50px !important;
  }
}
@media screen and (max-width: 1024px) {
  .text__80-1024 {
    font-size: 55px !important;
  }
}
@media screen and (max-width: 850px) {
  .text__80-md {
    font-size: 65px !important;
  }
}
@media screen and (max-width: 576px) {
  .text__80-sm {
    font-size: 50px !important;
  }
}
@media screen and (max-width: 480px) {
  .text__80-sm {
    font-size: 40px !important;
  }
}
@media screen and (max-width: 1024px) {
  .text__70-1024 {
    font-size: 60px !important;
  }
}
@media screen and (max-width: 850px) {
  .text__70-md {
    font-size: 50px !important;
  }
}
@media screen and (max-width: 576px) {
  .text__70-sm {
    font-size: 40px !important;
  }
}
@media screen and (max-width: 480px) {
  .text__70-xs {
    font-size: 40px !important;
  }
}
@media screen and (max-width: 375px) {
  .text__70-xxs {
    font-size: 35px !important;
  }
}
@media screen and (max-width: 1024px) {
  .text__65-1024 {
    font-size: 45px !important;
  }
}
@media screen and (max-width: 576px) {
  .text__65-sm {
    font-size: 35px !important;
  }
}
@media screen and (max-width: 1024px) {
  .text__60-1024 {
    font-size: 50px !important;
  }
}
@media screen and (max-width: 576px) {
  .text__60-sm {
    font-size: 35px !important;
  }
}
@media screen and (max-width: 375px) {
  .text__60-xxs {
    font-size: 30px !important;
  }
}
@media screen and (max-width: 1024px) {
  .text__50-1024 {
    font-size: 40px !important;
  }
}
@media screen and (max-width: 576px) {
  .text__50-sm {
    font-size: 35px !important;
  }
}
@media screen and (max-width: 375px) {
  .text__50-xxs {
    font-size: 30px !important;
  }
}
@media screen and (max-width: 375px) {
  .text__50-xxs-28 {
    font-size: 28px !important;
  }
}
@media screen and (max-width: 375px) {
  .text__50-xxs-20 {
    font-size: 20px !important;
  }
}
@media screen and (max-width: 576px) {
  .text__25-sm {
    font-size: 20px !important;
  }
}
@media screen and (max-width: 1024px) {
  .text__45-1024 {
    font-size: 35px !important;
  }
}
@media screen and (max-width: 850px) {
  .text__45-md {
    font-size: 30px !important;
  }
}
@media screen and (max-width: 512px) {
  .text__45-mm {
    font-size: 24px !important;
  }
}
@media screen and (max-width: 1024px) {
  .text__40-1024 {
    font-size: 30px !important;
  }
}
@media screen and (max-width: 850px) {
  .text__40-md {
    font-size: 30px !important;
  }
}
@media screen and (max-width: 512px) {
  .text__40-mm {
    font-size: 24px !important;
  }
}
@media screen and (max-width: 1024px) {
  .text__30-1024 {
    font-size: 25px !important;
  }
}
@media screen and (max-width: 850px) {
  .text__30-md {
    font-size: 25px !important;
  }
}
@media screen and (max-width: 576px) {
  .text__30-sm {
    font-size: 25px !important;
  }
}
@media screen and (max-width: 425px) {
  .text__30-xx {
    font-size: 20px !important;
  }
}
@media screen and (max-width: 1024px) {
  .text__32-1024 {
    font-size: 25px !important;
  }
}
@media screen and (max-width: 480px) {
  .text__32-xs {
    font-size: 18px !important;
  }
}
@media screen and (max-width: 1024px) {
  .text__36-1024 {
    font-size: 28px !important;
  }
}
@media screen and (max-width: 1024px) {
  .text__28-1024 {
    font-size: 20px !important;
  }
}
@media screen and (max-width: 576px) {
  .text__28-sm {
    font-size: 20px !important;
  }
}
@media screen and (max-width: 1024px) {
  .text__35-1024 {
    font-size: 28px !important;
  }
}
@media screen and (max-width: 850px) {
  .text__35-md {
    font-size: 20px !important;
  }
}
@media screen and (max-width: 480px) {
  .text__35-xs {
    font-size: 20px !important;
  }
}
@media screen and (max-width: 1024px) {
  .text__38-1024 {
    font-size: 30px !important;
  }
}
@media screen and (max-width: 1024px) {
  .text__90-1024 {
    font-size: 80px !important;
  }
}
@media screen and (max-width: 850px) {
  .text__90-md {
    font-size: 70px !important;
  }
}
@media screen and (max-width: 576px) {
  .text__90-sm {
    font-size: 55px !important;
  }
}
@media screen and (max-width: 480px) {
  .text__90-xs {
    font-size: 40px !important;
  }
}
@media screen and (max-width: 480px) {
  .text__90-xxs {
    font-size: 38px !important;
  }
}
@media screen and (max-width: 576px) {
  .text__33-sm {
    font-size: 25px !important;
  }
}
@media screen and (max-width: 425px) {
  .text__33-xx {
    font-size: 18px !important;
  }
}
@media screen and (max-width: 1024px) {
  .text__18-1024 {
    font-size: 16px !important;
  }
}
@media screen and (max-width: 576px) {
  .text__18-sm {
    font-size: 16px !important;
  }
}
@media screen and (max-width: 480px) {
  .text__18-xs {
    font-size: 14px !important;
  }
}
@media screen and (max-width: 375px) {
  .text__18-xxs {
    font-size: 12px !important;
  }
}
@media screen and (max-width: 1024px) {
  .text__16-1024 {
    font-size: 14px !important;
  }
}
@media screen and (max-width: 1024px) {
  .text__16-1024-12 {
    font-size: 12px !important;
  }
}
@media screen and (max-width: 1024px) {
  .text__16-1024-10 {
    font-size: 10px !important;
  }
}
@media screen and (max-width: 850px) {
  .text__16-md {
    font-size: 12px !important;
  }
}
@media screen and (max-width: 576px) {
  .text__16-sm {
    font-size: 12px !important;
  }
}
@media screen and (max-width: 512px) {
  .text__16-mm {
    font-size: 10px !important;
  }
}
@media screen and (max-width: 512px) {
  .text__16-mm-12 {
    font-size: 12px !important;
  }
}
@media screen and (max-width: 425px) {
  .text__16-xx {
    font-size: 10px !important;
  }
}
@media screen and (max-width: 1024px) {
  .text__20-1024 {
    font-size: 16px !important;
  }
}
@media screen and (max-width: 576px) {
  .text__20-sm {
    font-size: 14px !important;
  }
}
@media screen and (max-width: 1024px) {
  .text__120-1024 {
    font-size: 100px !important;
  }
}
@media screen and (max-width: 850px) {
  .text__120-md {
    font-size: 69px !important;
  }
}
@media screen and (max-width: 576px) {
  .text__120-sm {
    font-size: 60px !important;
  }
}
@media screen and (max-width: 375px) {
  .text__120-xxs {
    font-size: 50px !important;
  }
}
@media screen and (max-width: 850px) {
  .text__26-md {
    font-size: 20px !important;
  }
}
@media screen and (max-width: 576px) {
  .text__26-sm {
    font-size: 14px !important;
  }
}
@media screen and (max-width: 850px) {
  .text__22-md {
    font-size: 16px !important;
  }
}
@media screen and (max-width: 576px) {
  .text__22-sm {
    font-size: 14px !important;
  }
}
@media screen and (max-width: 850px) {
  .text__12-md {
    font-size: 10px !important;
  }
}
@media screen and (max-width: 576px) {
  .text__12-sm {
    font-size: 10px !important;
  }
}
@media screen and (max-width: 576px) {
  .text__12-sm-8 {
    font-size: 8px !important;
  }
}
@media screen and (max-width: 480px) {
  .text__12-xs {
    font-size: 8px !important;
  }
}
@media screen and (max-width: 480px) {
  .text__12-xs-10 {
    font-size: 10px !important;
  }
}
@media screen and (max-width: 512px) {
  .text__12-mm {
    font-size: 8px !important;
  }
}
@media screen and (max-width: 512px) {
  .text__12-mm-10 {
    font-size: 10px !important;
  }
}
@media screen and (max-width: 1024px) {
  .text__14-1024 {
    font-size: 12px !important;
  }
}
@media screen and (max-width: 1024px) {
  .text__14-1024-10 {
    font-size: 10px !important;
  }
}
@media screen and (max-width: 850px) {
  .text__14-md {
    font-size: 12px !important;
  }
}
@media screen and (max-width: 576px) {
  .text__14-sm-8 {
    font-size: 8px !important;
  }
}
@media screen and (max-width: 512px) {
  .text__14-mm {
    font-size: 9px !important;
  }
}
@media screen and (max-width: 512px) {
  .text__14-mm-10 {
    font-size: 10px !important;
  }
}
@media screen and (max-width: 425px) {
  .text__14-xx {
    font-size: 10px !important;
  }
}
@media screen and (max-width: 425px) {
  .text__14-xx-8 {
    font-size: 8px !important;
  }
}
@media screen and (max-width: 1024px) {
  .text__27-1024 {
    font-size: 18px !important;
  }
}
@media screen and (max-width: 1024px) {
  .text__24-1024 {
    font-size: 18px !important;
  }
}
@media screen and (max-width: 576px) {
  .text__24-sm {
    font-size: 16px !important;
  }
}
@media screen and (max-width: 1024px) {
  .text__25-1024 {
    font-size: 20px !important;
  }
}
@media screen and (max-width: 1024px) {
  .text__36-1024 {
    font-size: 25px !important;
  }
}
@media screen and (max-width: 576px) {
  .text__36-sm {
    font-size: 30px !important;
  }
}
@media screen and (max-width: 576px) {
  .text__58-sm {
    font-size: 45px !important;
  }
}
@media screen and (max-width: 1024px) {
  .text__48-1024 {
    font-size: 35px !important;
  }
}
@media screen and (max-width: 850px) {
  .text__48-md {
    font-size: 40px !important;
  }
}
@media screen and (max-width: 576px) {
  .text__48-sm {
    font-size: 30px !important;
  }
}

@media screen and (max-width: 1024px) {
  .wrap__flex-1024-100 {
    flex-flow: wrap;
  }
}
@media screen and (max-width: 1024px) {
  .wrap__flex-1024-100 > .grid {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}
@media screen and (max-width: 1024px) {
  .wrap__flex-1024-50 {
    flex-flow: wrap;
  }
}
@media screen and (max-width: 1024px) {
  .wrap__flex-1024-50 > .grid {
    flex: 0 0 45%;
    max-width: 45%;
  }
}
@media screen and (max-width: 1024px) {
  .wrap__flex-1024-90-3 {
    flex-flow: wrap;
  }
}
@media screen and (max-width: 1024px) {
  .wrap__flex-1024-90-3 > .grid {
    flex: 0 0 30%;
    max-width: 30%;
  }
}
@media screen and (max-width: 1024px) {
  .wrap__flex-1024-auto {
    flex-flow: wrap;
  }
}
@media screen and (max-width: 1024px) {
  .wrap__flex-1024-auto > .grid {
    flex: 0 0 auto !important;
    max-width: inherit !important;
  }
}
@media screen and (max-width: 850px) {
  .wrap__flex-md-100 {
    flex-flow: wrap;
  }
}
@media screen and (max-width: 850px) {
  .wrap__flex-md-100 > .grid {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media screen and (max-width: 850px) {
  .wrap__flex-md-50 {
    flex-flow: wrap;
  }
}
@media screen and (max-width: 850px) {
  .wrap__flex-md-50 > .grid {
    flex: 0 0 45%;
    max-width: 45%;
  }
}
@media screen and (max-width: 850px) {
  .wrap__flex-md-auto {
    flex-flow: wrap;
  }
}
@media screen and (max-width: 850px) {
  .wrap__flex-md-auto > .grid {
    flex: 0 0 auto !important;
    max-width: inherit !important;
  }
}
@media screen and (max-width: 576px) {
  .wrap__flex-sm-100 {
    flex-flow: wrap;
  }
}
@media screen and (max-width: 576px) {
  .wrap__flex-sm-100 > .grid {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media screen and (max-width: 576px) {
  .wrap__flex-sm-50 {
    flex-flow: wrap;
  }
}
@media screen and (max-width: 576px) {
  .wrap__flex-sm-50 > .grid {
    flex: 0 0 45%;
    max-width: 45%;
  }
}
@media screen and (max-width: 480px) {
  .wrap__flex-xs-100 {
    flex-flow: wrap;
  }
}
@media screen and (max-width: 480px) {
  .wrap__flex-xs-100 > .grid {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media screen and (max-width: 576px) {
  .wrap__flex-xs-50 {
    flex-flow: wrap;
  }
}
@media screen and (max-width: 576px) {
  .wrap__flex-xs-50 > .grid {
    flex: 0 0 45%;
    max-width: 45%;
  }
}
@media screen and (max-width: 512px) {
  .wrap__flex-mm-100 {
    flex-flow: wrap;
  }
}
@media screen and (max-width: 512px) {
  .wrap__flex-mm-100 > .grid {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media screen and (max-width: 576px) {
  .wrap__flex-mm-50 {
    flex-flow: wrap;
  }
}
@media screen and (max-width: 576px) {
  .wrap__flex-mm-50 > .grid {
    flex: 0 0 45%;
    max-width: 45%;
  }
}
@media screen and (max-width: 512px) {
  .wrap__flex-mm-4 {
    flex-flow: wrap;
  }
}
@media screen and (max-width: 512px) {
  .wrap__flex-mm-4 > .grid {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
}

@media (min-width: 120em) {
  .font__size--24 {
    font-size: 2.1em !important;
  }
}
@media (min-width: 160em) {
  .font__size--24 {
    font-size: 2.4em !important;
  }
}
@media (min-width: 120em) {
  .font__size--20 {
    font-size: 1.9em !important;
  }
}
@media (min-width: 160em) {
  .font__size--20 {
    font-size: 2.5em !important;
  }
}
@media (min-width: 160em) {
  .font__size--64 {
    font-size: 72px !important;
  }
}
@media (min-width: 120em) {
  .font__size--16 {
    font-size: 1.4em !important;
  }
}
@media (min-width: 160em) {
  .font__size--16 {
    font-size: 1.6em !important;
  }
}
@media (min-width: 120em) {
  .font__size--14 {
    font-size: 1.218em !important;
  }
}
@media (min-width: 160em) {
  .font__size--14 {
    font-size: 1.392em !important;
  }
}
@media (min-width: 120em) {
  .font__size--96 {
    font-size: 8.18em !important;
  }
}
@media (min-width: 160em) {
  .font__size--96 {
    font-size: 10em !important;
  }
}

.wrapper__dropdown .dropdown-menu {
  margin: 1.5rem 0 0;
  min-width: 100%;
  width: 332px;
  border: none;
  padding: 1rem 0 !important;
  box-shadow: 0px 64px 64px -48px rgba(15, 15, 15, 0.08);
}
.wrapper__dropdown .dropdown-menu .hr__line {
  padding: 0 1rem !important;
}
.wrapper__dropdown .dropdown-menu .dropdown-item:hover {
  box-shadow: none !important;
  background-color: transparent !important;
  outline-style: none !important;
  color: #082A3A !important;
}
.wrapper__dropdown .dropdown-menu .dropdown-item:active {
  box-shadow: none !important;
  background-color: transparent !important;
  outline-style: none !important;
  color: #082A3A !important;
}
.wrapper__dropdown .dropdown-menu .dropdown-item:focus {
  box-shadow: none !important;
  background-color: transparent !important;
  outline-style: none !important;
  color: #082A3A !important;
}
.wrapper__dropdown .dropdown-menu .profile-user {
  padding: 0 1rem !important;
  display: inline-block;
  width: 100%;
}
.wrapper__side-menu .notification .circle {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid white;
  right: -5px;
  top: -5px;
}
.wrapper__side-menu .dropdown .dropdown-menu {
  padding: 0;
}
.wrapper__side-menu .dropdown .dropdown-menu .dropdown-item {
  padding: 0.5rem 1rem;
}
.wrapper__side-nav-mobile {
  position: fixed;
  width: 100%;
  height: 100%;
  top: -100%;
  left: 0;
  background-color: #082A3A;
  z-index: 999;
  padding: 1.5rem;
  overflow: auto;
  transition: 0.5s;
}
.wrapper__side-nav-mobile div div .menu hr {
  border-top: 1px solid rgba(149, 149, 149, 0.1);
}
.wrapper__side-nav-mobile.active {
  top: 0%;
}
.wrapper__side-nav-mobile.active ~ .bg__wrap-menu {
  top: 0;
}
.wrapper__side-nav-mobile ~ .bg__wrap-menu {
  position: fixed;
  z-index: 998;
  width: 100%;
  height: 100%;
  top: -100%;
  left: 0;
  background: rgba(0, 0, 0, 0.32);
  backdrop-filter: blur(4px);
  transition: 0.5s;
}
.wrapper__navbar {
  padding-top: 1rem;
  padding-bottom: 1rem;
  z-index: 997 !important;
}
.wrapper__navbar.fixed {
  position: fixed !important;
  width: 100%;
  left: 0;
  top: 0;
  background-color: transparent !important;
}
.wrapper__navbar-menu .menu__center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: max-content;
}
@media screen and (max-width: 850px) {
  .wrapper__navbar-menu .menu__center {
    position: initial;
    left: initial;
    top: initial;
    transform: initial;
    width: 100%;
  }
}
.wrapper__navbar-menu ul li {
  margin: 0 15px;
}
@media screen and (max-width: 1024px) {
  .wrapper__navbar-menu ul li {
    margin: 0 10px;
  }
}
@media screen and (max-width: 850px) {
  .wrapper__navbar-menu ul li {
    margin: 0 0;
  }
}
.wrapper__navbar-menu ul li a {
  position: relative;
}
.wrapper__navbar-menu ul li a[aria-current=page] {
  opacity: 1;
  color: !important;
}
.wrapper__navbar-menu ul li a[aria-current=page]::after {
  opacity: 1;
}
.wrapper__navbar-menu ul li a:hover {
  color: !important;
}
.wrapper__navbar-menu ul li a:hover::after {
  opacity: 1;
}
.wrapper__navbar div .side {
  margin-right: -3rem;
  margin-top: -1rem;
  margin-bottom: -1rem;
}
.wrapper__navbar div .side .sidebar {
  width: 80px;
  height: 80px;
  background: rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}
.wrapper__navbar div .side .sidebar .arrow {
  width: 30px;
  height: 30px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
}
.wrapper__navbar div .side .sidebar .arrow span {
  width: 100%;
  height: 2px;
  background-color: #ffffff;
  margin-bottom: 3px;
  display: inline-block;
  transition: 0.5s;
}
.wrapper__navbar div .side .sidebar .arrow span:nth-child(2) {
  width: 80%;
}
.wrapper__navbar div .side .sidebar .arrow span:nth-child(3) {
  width: 60%;
  margin-bottom: 0;
}

.nav__button {
  width: 50px;
  height: 50px;
  padding: 15px;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  align-items: center;
  outline-style: none;
  position: relative;
  background-color: black;
  border-radius: 50%;
}
@media screen and (max-width: 576px) {
  .nav__button {
    width: 40px;
    height: 40px;
    padding: 10px;
  }
}
.nav__button::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 60px;
  height: 60px;
  background: linear-gradient(90deg, #0cbaf1 0%, #e95ce9 100%);
  border-radius: 50%;
  z-index: -1;
}
@media screen and (max-width: 576px) {
  .nav__button::before {
    width: 47px;
    height: 47px;
  }
}
.nav__button.light {
  background-color: rgba(255, 255, 255, 0.3490196078);
}
.nav__button.light span {
  background-color: white !important;
}
.nav__button span {
  width: 100%;
  height: 2px;
  background-color: #ffffff;
  margin-bottom: 5px;
  display: inline-block;
  transition: 0.5s;
}
.nav__button span:nth-child(2) {
  width: 80%;
}
.nav__button span:nth-child(3) {
  width: 60%;
  margin-bottom: 0;
}
.nav__button:active {
  outline-style: none;
}
.nav__button:hover {
  outline-style: none;
}
.nav__button:focus {
  outline-style: none;
}
.nav__button[aria-expanded=true] span:nth-child(1) {
  width: 16px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  margin-bottom: 0;
}
.nav__button[aria-expanded=true] span:nth-child(2) {
  width: 16px;
  display: none;
  margin-bottom: 0;
}
.nav__button[aria-expanded=true] span:nth-child(3) {
  width: 16px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
}

.input__control {
  background: #FFFFFF;
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  height: 68px;
}
.input__control:focus {
  box-shadow: none;
  border: 1px solid #E0E0E0;
}

